<script lang="ts" setup>
import { getCurrentInstance, computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { Option } from '@/helpers/Interfaces';

defineOptions({
    name: 'field-select'
});

const model = defineModel<any>();
const props = defineProps({
  "label": null,
  "options": null,
  "invalidFeedback": { type: Function, default: null },
  "required": { type: Boolean, default: false },
  "disabled": { type: Boolean, default: false },
  "description": { default: null }
});

const { $t } = useLocalization();
const instance = getCurrentInstance();
const uid = computed(() => `form-field-${instance.uid}`);
const options = computed(() => props.options.map(p => ({ value: p.value, text: $t(p.text) })));
</script>


<template>
    <ideo-form-group :label="props.label" :required="props.required">
        <ideo-form-select :id="uid" v-model="model" :options="options" :disabled="props.disabled"></ideo-form-select>
        <small class="form-text text-danger d-block" v-if="invalidFeedback && invalidFeedback(modelValue)">
            {{ $t(invalidFeedback(modelValue)) }}
        </small>
        <template #description v-if="props.description">
            {{ props.description }}
        </template>
    </ideo-form-group>
</template>
