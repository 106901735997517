import { Plugin } from 'vue';
import IdeoAccordion from './accordion/IdeoAccordion.vue';
import IdeoAccordionItem from './accordion/IdeoAccordionItem.vue';
import IdeoAlert from './alert/IdeoAlert.vue';
import IdeoAutocomplete from './autocomplete/IdeoAutocomplete.vue';
import IdeoButton from './button/IdeoButton.vue';
import IdeoButtonGroup from './button/IdeoButtonGroup.vue';
import IdeoCollapse from './collapse/IdeoCollapse.vue';
import IdeoColor from './color/IdeoColor.vue';
import IdeoComboBox from './combobox/IdeoComboBox.vue';
import IdeoDateTimeNew from './datetime/IdeoDateTimeNew.vue';
import IdeoDropdown from './dropdown/IdeoDropdown.vue';
import IdeoDropdownDivider from './dropdown/IdeoDropdownDivider.vue';
import IdeoDropdownHeader from './dropdown/IdeoDropdownHeader.vue';
import IdeoDropdownItem from './dropdown/IdeoDropdownItem.vue';
import IdeoDropdownItemButton from './dropdown/IdeoDropdownItemButton.vue';
import IdeoDropdownText from './dropdown/IdeoDropdownText.vue';
import IdeoForm from './form/IdeoForm.vue';
import IdeoFormCheckbox from './form-checkbox/IdeoFormCheckbox.vue';
import IdeoFormCheckboxGroup from './form-checkbox/IdeoFormCheckboxGroup.vue';
import IdeoFormGroup from './form-group/IdeoFormGroup.vue';
import IdeoFormInput from './form-input/IdeoFormInput.vue';
import IdeoFormMultiselect from './form-multiselect/IdeoFormMultiselect.vue';
import IdeoFormRadio from './form-radio/IdeoFormRadio.vue';
import IdeoFormRadioGroup from './form-radio/IdeoFormRadioGroup.vue';
import IdeoFormSelect from './form-select/IdeoFormSelect.vue';
import IdeoFormSelectOption from './form-select/IdeoFormSelectOption.vue';
import IdeoFormSelectOptionGroup from './form-select/IdeoFormSelectOptionGroup.vue';
import IdeoFormTextarea from './form-textarea/IdeoFormTextarea.vue';
import IdeoFormLocalize from './form-localize/IdeoFormLocalize.vue';
import IdeoImage from './image/IdeoImage.vue';
import IdeoInputGroup from './input-group/IdeoInputGroup.vue';
import IdeoInputGroupText from './input-group/IdeoInputGroupText.vue';
import IdeoModal from './modal/IdeoModal.vue';
import IdeoPanel from './panel/IdeoPanel.vue';
import IdeoPopover from './popover/IdeoPopover.vue';
import IdeoTabs from './tabs/IdeoTabs.vue';
import IdeoTab from './tabs/IdeoTab.vue';
import IdeoTabStrip from './tabs/IdeoTabStrip.vue';
import IdeoTree from './tree/IdeoTree.vue';
import IdeoTreeNode from './tree/IdeoTreeNode.vue';
import IdeoTreeNodes from './tree/IdeoTreeNodes.vue';
import IdeoTooltip from './tooltip/IdeoTooltip.vue';
import IdeoExtendedList from './extended-list/IdeoExtendedList.vue';

const IdeoPlugin: Plugin =
{
    install(app, options)
    {
        app.component('IdeoAccordion', IdeoAccordion);
        app.component('IdeoAccordionItem', IdeoAccordionItem);
        app.component('IdeoAlert', IdeoAlert);
        app.component('IdeoAutocomplete', IdeoAutocomplete);
        app.component('IdeoButton', IdeoButton);
        app.component('IdeoButtonGroup', IdeoButtonGroup);
        app.component('IdeoCollapse', IdeoCollapse);
        app.component('IdeoColor', IdeoColor);
        app.component('IdeoComboBox', IdeoComboBox);
        app.component('IdeoDatetime', IdeoDateTimeNew);
        app.component('IdeoDropdown', IdeoDropdown);
        app.component('IdeoDropdownDivider', IdeoDropdownDivider);
        app.component('IdeoDropdownHeader', IdeoDropdownHeader);
        app.component('IdeoDropdownItem', IdeoDropdownItem);
        app.component('IdeoDropdownItemButton', IdeoDropdownItemButton);
        app.component('IdeoDropdownText', IdeoDropdownText);
        app.component('IdeoForm', IdeoForm);
        app.component('IdeoFormCheckbox', IdeoFormCheckbox);
        app.component('IdeoFormCheckboxGroup', IdeoFormCheckboxGroup);
        app.component('IdeoFormGroup', IdeoFormGroup);
        app.component('IdeoFormInput', IdeoFormInput);
        app.component('IdeoFormMultiselect', IdeoFormMultiselect);
        app.component('IdeoFormRadio', IdeoFormRadio);
        app.component('IdeoFormRadioGroup', IdeoFormRadioGroup);
        app.component('IdeoFormSelect', IdeoFormSelect);
        app.component('IdeoFormSelectOption', IdeoFormSelectOption);
        app.component('IdeoFormSelectOptionGroup', IdeoFormSelectOptionGroup);
        app.component('IdeoFormTextarea', IdeoFormTextarea);
        app.component('IdeoFormLocalize', IdeoFormLocalize);
        app.component('IdeoImg', IdeoImage);
        app.component('IdeoInputGroup', IdeoInputGroup);
        app.component('IdeoInputGroupText', IdeoInputGroupText);
        app.component('IdeoModal', IdeoModal);
        app.component('IdeoPanel', IdeoPanel);
        app.component('IdeoPopover', IdeoPopover);
        app.component('IdeoTabs', IdeoTabs);
        app.component('IdeoTab', IdeoTab);
        app.component('IdeoTabStrip', IdeoTabStrip);
        app.component('IdeoTree', IdeoTree);
        app.component('IdeoTreeNode', IdeoTreeNode);
        app.component('IdeoTreeNodes', IdeoTreeNodes);
        app.component('IdeoTooltip', IdeoTooltip);
        app.component('IdeoExtendedList', IdeoExtendedList);
    }
};

export default IdeoPlugin;
