import { DateTime } from 'luxon';
import { axios } from '@/plugins/axios';
import { $t } from '@/plugins/localization';
import Pager from '@/helpers/Pager';
import { Pagination, Resource, Statement, Option } from '@/helpers/Interfaces';
import { FormEntry } from '@/components/forms/blueprints/form';

/**
 * DataSourcesService
 */
export default class DataSourcesService
{
    /**
     * @returns Promise<Option[]>
     */
    public static async getDictionaries(): Promise<Option[]>
    {
        return (await axios.get<Option[]>('admin/studio/data-sources/all')).data;
    }

    /**
     * @param sourceId number
     * @param query string
     * @param pager Pager
     *
     * @returns Promise<Pagination<Option>>
     */
    public static async getOptions(sourceId: number, query: string, pager: Pager): Promise<Pagination<Option>>
    {
        return (await axios.get<Pagination<Option>>(`admin/studio/data-sources/id/${sourceId}/options`, {
            params: { query: query, ...pager.data() }
        })).data;
    }

    public static async getOptionsById(sourceId: number, ids: number[], pager: Pager): Promise<Pagination<Option>>
    {
        return (await axios.post<Pagination<Option>>(`admin/studio/data-sources/id/${sourceId}/options`, { ids }, {
            params: pager.data()
        })).data;
    }

    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/studio/data-sources', {
            params: {...filter, ...pager.data()}
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(id: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/studio/data-sources/${id}`)).data;
    }

    /**
     * @param model FormModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async create(model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`admin/studio/data-sources`, model)).data;
    }

    /**
     * @param id number
     * @param model FormModel
     *
     * @returns Promise<Statement>
     */
    public static async update(id: number, model: FormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/studio/data-sources/${id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/studio/data-sources/${id}`)).data as Statement;
    }

    /**
     * @param sourceId number
     * @param filter ValueFilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<ValueItemModel>>
     */
    public static async getValues(sourceId: number, filter: ValueFilterModel, pager: Pager): Promise<Pagination<ValueItemModel>>
    {
        return (await axios.get<Pagination<ValueItemModel>>(`admin/studio/data-sources/${sourceId}/values`, {
            params: {...filter, ...pager.data()}
        })).data;
    }

    /**
     * @param sourceId number
     * @param model FormModel
     *
     * @returns Promise<ValueModel>
     */
    public static async addValue(sourceId: number, model: ValueModel): Promise<ValueModel>
    {
        return (await axios.post<ValueModel>(`admin/studio/data-sources/${sourceId}/values`, model)).data;
    }

    /**
     * @param sourceId number
     * @param model FormModel
     *
     * @returns Promise<Statement>
     */
    public static async updateValue(sourceId: number, model: ValueModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/studio/data-sources/${sourceId}/values/${model.id}`, model)).data;
    }

    /**
     * @param sourceId number
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async removeValue(sourceId: number, id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/studio/data-sources/${sourceId}/values/${id}`)).data as Statement;
    }
}

export interface ListItemModel
{
    id: number;
    dateCreatedUtc: DateTime;
    name: string;
    sourceType: string;
    dictionaryType: string;
}

export interface FilterModel
{
    name: string;
    sourceType: SourceTypeEnum;
    dictionaryType: string;
    dateFromUtc?: DateTime;
    dateDueUtc?: DateTime;
}

export interface FormModel
{
    id: number;
    name: string;
    description: string;
    sourceType: SourceTypeEnum;
    dictionaryType: string;
    query: string;
    queryById: string,
    textField: string;
    valueField: string;
    // formOrigin?: number;
    formFilters: FormEntry;
    moduleId?: number;
    sectionId?: string;
    componentId?: string;
}

export interface ValueFilterModel
{
    text: string;
    value: string;
}

export interface ValueItemModel
{
    id: number;
    text: string;
    value: string;
    position: number;
    isDefault: boolean;
}

export interface ValueModel
{
    id: number;
    text: string;
    value: string;
    position: number;
    isDefault: boolean;
}

enum SourceTypeEnum {
    Dictionary = 'Dictionary',
    Database = 'Database',
    Form = 'Form'
}

const SourceTypeOptions = (): Option[] => ([
    { value: SourceTypeEnum.Dictionary, text: $t('[[[Słownik]]]') },
    { value: SourceTypeEnum.Database, text: $t('[[[Baza danych]]]') },
    { value: SourceTypeEnum.Form, text: $t('[[[Formularz]]]') }
]);

const SourceTypeName = (value: SourceTypeEnum): string => SourceTypeOptions().find(p => p.value === value)?.text || SourceTypeEnum[value];

export {
    SourceTypeEnum,
    SourceTypeOptions,
    SourceTypeName
};
