export default [
    {
        name: '[[[Programy lojalnościowe]]]',
        icon: 'fa-solid fa-list',
        namespace: 'Ideo.Core.Workspace.Application.Permissions.Catalog',
        children: [
            {
                name: '[[[Zarządzanie Programami]]]',
                route: 'programs',
                auth: { all: ['ProgramsPermissions@View'], any: [] },
                icon: 'fa-solid fa-list-check',
                children: [
                    {
                        name: '[[[Dodanie programu]]]',
                        route: 'programs-add',
                        auth: { all: ['ProgramsPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja programu]]]',
                        route: 'programs-edit',
                        auth: { all: ['ProgramsPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
        ]
    },
    {
        name: '[[[Katalog nagród]]]',
        icon: 'fad fa-fw fa-gift',
        namespace: 'Ideo.Core.Workspace.Application.Permissions.Catalog',
        children: [
            {
                name: '[[[Katalog produktów]]]',
                route: 'products',
                icon: 'fad fa-fw fa-gift',
                auth: { all: ['ProductsPermissions@View'], any: [] },
                children:[
                    {
                        name: '[[[Dodanie produktu]]]',
                        route: 'products-add',
                        auth: { all: ['ProductsPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Edycja produktu]]]',
                        route: 'products-edit',
                        auth: { all: ['ProductsPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Hurtowa edycja]]]',
                        route: 'products-edit-selected',
                        auth: { all: ['ProductsPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Import produktów]]]',
                        route: 'products-import',
                        auth: { all: ['ProductsPermissions@Edit'], any: [] },
                        visible: false,
                    },
                ]
            },
            {
                name: '[[[Zarządzanie zamówieniami]]]',
                route: 'orders',
                icon: 'fa-solid fa-list',
                auth: { all: ['OrdersPermissions@View'], any: [] },
                children:[
                    {
                        name: '[[[Dodanie zamówienia]]]',
                        route: 'orders-add',
                        auth: { all: ['OrdersPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Edycja zamówienia]]]',
                        route: 'orders-edit',
                        auth: { all: ['OrdersPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Edytuj wybrane zamówienia]]]',
                        route: 'orders-edit-selected',
                        auth: { all: ['OrdersPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Importuj statusy zamówień]]]',
                        route: 'orders-import-statuses',
                        auth: { all: ['OrdersPermissions@ExportAndImport'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Statystyki zamówień]]]',
                        route: 'orders-statistics',
                        auth: { all: ['OrdersPermissions@Edit'], any: [] },
                        visible: false,
                    },
                ]
            },
            {
                name: '[[[Podsumowanie programów]]]',
                route: 'programs-summary',
                icon: 'fa-solid fa-chart-pie',
                auth: { all: ['ProgramSummaryPermissions@View'], any: [] }
            },
            {
                name: '[[[Awizacja]]]',
                icon: 'fa fa-fw fa-user-circle'
            },
            {
                name: '[[[Kategorie produktów]]]',
                route: 'product-categories',
                icon: 'fa-solid fa-layer-group',
                auth: { all: ['ProductsCategoriesPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie kategorii produktów]]]',
                        route: 'product-categories-add',
                        auth: { all: ['ProductsCategoriesPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Edycja kategorii produktów]]]',
                        route: 'product-categories-edit',
                        auth: { all: ['ProductsCategoriesPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Sortowanie kategorii produktów]]]',
                        route: 'product-categories-sort',
                        auth: { all: ['ProductsCategoriesPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Mapowanie kategorii produktów]]]',
                        route: 'product-categories-map',
                        auth: { all: ['ProductsCategoriesPermissions@Map'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Edycja mapowania kategorii produktów]]]',
                        route: 'product-categories-map-edit',
                        auth: { all: ['ProductsCategoriesPermissions@Edit'], any: [] },
                        visible: false,
                    }
                ]
            },
            {
                name: '[[[Dostawcy]]]',
                route: 'suppliers',
                icon: 'fa-solid fa-truck',
                auth: { all: ['SuppliersPermissions@View'], any: [] },
                children:[
                    {
                        name: '[[[Dodaj dostawcę]]]',
                        visible: false,
                        route: 'suppliers-add',
                        auth: { all: ['SuppliersPermissions@Edit'], any: [] },
                    },
                    {
                        name: '[[[Edytuj dostawcę]]]',
                        visible: false,
                        route: 'suppliers-edit',
                        auth: { all: ['SuppliersPermissions@Edit'], any: [] },
                    },
                ],
            },
            {
                name: '[[[Reklamacje]]]',
                route: 'complaints',
                icon: 'fa-solid fa-arrows-spin',
                auth: { all: ['ComplaintsPermissions@View'], any: [] },
                children:[
                    {
                        name: '[[[Dodaj reklamację]]]',
                        visible: false,
                        route: 'complaints-add',
                        auth: { all: ['ComplaintsPermissions@Edit'], any: [] },
                    },
                    {
                        name: '[[[Edytuj reklamację]]]',
                        visible: false,
                        route: 'complaints-edit',
                        auth: { all: ['ComplaintsPermissions@Edit'], any: [] },
                    },
                    {
                        name: '[[[Lista]]]',
                        visible: false,
                        route: 'complaints-list',
                        auth: { all: ['ComplaintsPermissions@View'], any: [] },
                    },
                    {
                        name: '[[[Edycja wielu]]]',
                        visible: false,
                        route: 'complaints-edit-multiple',
                        auth: { all: ['ComplaintsPermissions@Edit'], any: [] },
                    },
                ]
            },
            {
                name: '[[[System banerowy]]]',
                route: 'banners',
                icon: 'fa-solid fa-image',
                auth: { all: ['BannersPermissions@View'], any: [] },
                children:[
                    {
                        name: '[[[Dodaj baner]]]',
                        visible: false,
                        route: 'banners-add',
                        icon: 'fa fa-fw fa-user-circle',
                        auth: { all: ['BannersPermissions@Edit'], any: [] },
                    },
                    {
                        name: '[[[Edytuj baner]]]',
                        visible: false,
                        route: 'banners-edit',
                        icon: 'fa fa-fw fa-user-circle',
                        auth: { all: ['BannersPermissions@Edit'], any: [] },
                    }
                ]
            },
            {
                name: '[[[Raporty]]]',
                icon: 'fa-solid fa-chart-line'
            },
            {
                name: '[[[Infolinia]]]',
                route: 'phone-connections',
                icon: 'fa-solid fa-phone',
                auth: { all: ['PhoneConnectionsPermissions@View'], any: [] },
                children:[
                    {
                        name: '[[[Dodaj połączenie]]]',
                        visible: false,
                        route: 'phone-connections-add',
                        auth: { all: ['PhoneConnectionsPermissions@Edit'], any: [] },
                    },
                    {
                        name: '[[[Edytuj połączenie]]]',
                        visible: false,
                        route: 'phone-connections-edit',
                        auth: { all: ['PhoneConnectionsPermissions@Edit'], any: [] },
                    },
                    {
                        name: '[[[Lista]]]',
                        visible: false,
                        route: 'phone-connections-list',
                        auth: { all: ['PhoneConnectionsPermissions@Edit'], any: [] },
                    },
                ]
            },
        ]
    },
    {
        name: '[[[Karty Visa]]]',
        icon: 'fa-solid fa-credit-card',
        namespace: 'Ideo.Core.Workspace.Application.Permissions.PrepaidCard',
        children: [
            {
                name: '[[[Zarządzanie zamówieniami]]]',
                icon: 'fa-solid fa-list',
                route: 'prepaid-card-orders',
                auth: { all: ['OrdersPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodaj spoza platformy]]]',
                        route: 'prepaid-card-orders-add',
                        auth: { all: ['OrdersPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Edytuj zamówienie]]]',
                        route: 'prepaid-card-orders-edit',
                        auth: { all: ['OrdersPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Importuj zamówienia]]]',
                        route: 'prepaid-card-orders-import',
                        auth: { all: ['OrdersPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Importuj powiązania z numerami]]]',
                        route: 'prepaid-card-assign-orders-to-cards-import',
                        auth: { all: ['OrdersPermissions@Edit'], any: [] },
                        visible: false,
                    },
                ],
            },
            {
                name: '[[[Rozliczenia]]]',
                route: 'prepaid-card-settlements',
                icon: 'fa-solid fa-coins',
                auth: { all: ['SettlementsPermissions@View'], any: [] },
                visible: false,
                children:[
                    {
                        name: '[[[Dodaj rozliczenie]]]',
                        route: 'prepaid-card-settlements-add',
                        auth: { all: ['SettlementsPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Edytuj rozliczenie]]]',
                        route: 'prepaid-card-settlements-edit',
                        auth: { all: ['SettlementsPermissions@Edit'], any: [] },
                        visible: false,
                    }
                ],
            },
            {
                name: '[[[Karty]]]',
                route: 'prepaid-card-numbers',
                icon: 'fa-solid fa-credit-card',
                auth: { all: ['CardsNumbersPermissions@View'], any: [] },
                children:[
                    {
                        name: '[[[Dodaj kartę]]]',
                        route: 'prepaid-card-numbers-add',
                        auth: { all: ['CardsNumbersPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Edytuj kartę]]]',
                        route: 'prepaid-card-numbers-edit',
                        auth: { all: ['CardsNumbersPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Importuj karty]]]',
                        route: 'prepaid-card-numbers-import',
                        auth: { all: ['CardsNumbersPermissions@ExportAndImport'], any: [] },
                        visible: false,
                    }
                ],
            },
            {
                name: '[[[Historia dyspozycji]]]',
                route: 'prepaid-card-acceptance-logs',
                icon: 'fa-solid fa-timeline',
                auth: { all: ['AcceptanceLogsPermissions@View'], any: [] },
                children:[
                    {
                        name: '[[[Szczegóły dyspozycji]]]',
                        auth: { all: ['AcceptanceLogsPermissions@View'], any: [] },
                        route: 'prepaid-card-acceptance-logs-details',
                        visible: false,
                    },
                ],
            },
        ]
    },
    {
        name: '[[[Blik]]]',
        icon: 'fa-solid fa-dollar',
        namespace: 'Ideo.Core.Workspace.Application.Permissions.Blik',
        children: [
            {
                name: '[[[Zarządzanie zamówieniami]]]',
                route: 'blik-orders',
                icon: 'fa-solid fa-list',
                auth: { all: ['OrdersPermissions@View'], any: [] },
                children:[
                    {
                        name: '[[[Dodaj zamówienie]]]',
                        route: 'blik-orders-add',
                        auth: { all: ['OrdersPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Edytuj zamówienie]]]',
                        route: 'blik-orders-edit',
                        auth: { all: ['OrdersPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Importuj zamówienie]]]',
                        route: 'blik-orders-import',
                        auth: { all: ['OrdersPermissions@ExportAndImport'], any: [] },
                        visible: false,
                    },
                ]
            },
            {
                name: '[[[Rozliczenia]]]',
                route: 'blik-settlements',
                icon: 'fa-solid fa-coins',
                auth: { all: ['SettlementsPermissions@View'], any: [] },
                children:[
                    {
                        name: '[[[Dodaj rozliczenie]]]',
                        route: 'blik-settlements-add',
                        auth: { all: ['SettlementsPermissions@Edit'], any: [] },
                        visible: false,
                    },
                    {
                        name: '[[[Edytuj rozliczenie]]]',
                        route: 'blik-settlements-edit',
                        auth: { all: ['SettlementsPermissions@Edit'], any: [] },
                        visible: false,
                    },
                ]
            },
        ]
    },
    {
        name: '[[[Doładowania telefonów]]]',
        icon: 'fa-solid fa-mobile-screen-button',
        children: [
            {
                name: '[[[Zarządzanie zamówieniami]]]',
                icon: 'fa-solid fa-list',
            },
            {
                name: '[[[Rozliczenia]]]',
                icon: 'fa-solid fa-coins'
            },
            {
                name: '[[[Produkty]]]',
                icon: 'fa-solid fa-boxes-stacked'
            },
        ]
    },
    {
        name: '[[[Raporty i stany]]]',
        namespace: 'Ideo.Core.Workspace.Application.Permissions.ReportsStates',
        auth: { all: ['ReportsStatesPermissions@View'], any: [] },
        icon: 'fa-solid fa-chart-line',
        route: 'report'
    },
    {
        name: '[[[Konfiguracja]]]',
        icon: 'fa-solid fa-screwdriver-wrench',
        children: [
            {
                name: '[[[Konfiguracja drukarki]]]',
                icon: 'fa-solid fa-print',
                auth: { all: ['SettingsPermissions@Codes'], any: [] },
                namespace: 'Ideo.Core.Workspace.Application.Permissions.Settings',
                route: 'printers',
                children:[
                    {
                        name: '[[[Kody błędów drukarki]]]',
                        route: 'printers-codes',
                        auth: { all: ['PrinterPermissions@Codes'], any: [] },
                        visible: false,
                    }
                ]
            }
        ]
    },
    {
        name: '[[[Moje konto]]]',
        icon: 'fas fa-fw fa-user',
        children: [
            {
                name: '[[[Profil użytkownika]]]',
                route: 'core-account-profile',
                icon: 'fa fa-fw fa-user-circle'
            },
            {
                name: '[[[Zmiana hasła]]]',
                route: 'core-account-password',
                icon: 'fa fa-fw fa-toggle-on'
            },
            {
                name: '[[[Centrum powiadomień]]]',
                route: 'core-account-notifications',
                icon: 'far fa-fw fa-envelope-open',
                query: {status: 'unreaded'},
                event: 'notifications::reload-list'
            },
            {
                name: '[[[Bezpieczeństwo]]]',
                route: 'core-account-security',
                icon: 'fas fa-fw fa-phone-laptop'
            },
            {
                name: '[[[Tokeny dostępu]]]',
                route: 'core-pats',
                auth: { all: ['StandardPermissions@Superuser'], any: [] },
                icon: 'fas fa-fw fa-key'
            }
        ]
    },
    {
        name: '[[[Usługi]]]',
        short: '[[[Usługi]]]',
        icon: 'fa-light fa-server',
        namespace: 'Ideo.Core.Common.Application.Permissions.Saas',
        children: [
            {
                name: '[[[Klienci]]]',
                route: 'saas-tenants',
                icon: 'fas fa-fw fa-users-class',
                auth: { all: ['TenantsPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie klienta]]]',
                        route: 'saas-tenants-add',
                        auth: { all: ['TenantsPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja klienta]]]',
                        route: 'saas-tenants-edit',
                        auth: { all: ['TenantsPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Bazy danych]]]',
                route: 'saas-databases',
                icon: 'fas fa-fw fa-database',
                auth: { all: ['DatabasesPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie serwera]]]',
                        route: 'saas-databases-add',
                        auth: { all: ['DatabasesPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja serwera]]]',
                        route: 'saas-databases-edit',
                        auth: { all: ['DatabasesPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Serwery plików]]]',
                route: 'saas-storages',
                icon: 'fas fa-fw fa-hdd',
                auth: { all: ['StoragesPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie serwera]]]',
                        route: 'saas-storages-add',
                        auth: { all: ['StoragesPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja serwera]]]',
                        route: 'saas-storages-edit',
                        auth: { all: ['StoragesPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            }
        ]
    },
    {
        name: '[[[System]]]',
        short: '[[[System]]]',
        icon: 'fal fa-display',
        namespace: 'Ideo.Core.Common.Application.Permissions.Core',
        children: [
            {
                name: '[[[Administracja]]]',
                icon: 'far fa-fw fa-folder-user',
                children: [
                    {
                        name: '[[[Użytkownicy]]]',
                        route: 'core-users',
                        icon: 'far fa-fw fa-user',
                        auth: { all: ['UsersPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie użytkownika]]]',
                                route: 'core-users-add',
                                auth: { all: ['UsersPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja użytkownika]]]',
                                route: 'core-users-edit',
                                auth: { all: ['UsersPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Role i uprawnienia]]]',
                        route: 'core-roles',
                        icon: 'far fa-fw fa-user-shield',
                        auth: { all: ['RolesPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie roli]]]',
                                route: 'core-roles-add',
                                auth: { all: ['RolesPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja roli]]]',
                                route: 'core-roles-edit',
                                auth: { all: ['RolesPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Organizacja]]]',
                        route: 'core-units',
                        icon: 'far fa-fw fa-sitemap',
                        auth: { all: ['OrganizationPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie działu]]]',
                                route: 'core-units-add',
                                auth: { all: ['OrganizationPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja działu]]]',
                                route: 'core-units-edit',
                                auth: { all: ['OrganizationPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Zespoły]]]',
                        route: 'core-teams',
                        icon: 'far fa-fw fa-users',
                        auth: { all: ['TeamsPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie zespołu]]]',
                                route: 'core-teams-add',
                                auth: { all: ['TeamsPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja zespołu]]]',
                                route: 'core-teams-edit',
                                auth: { all: ['TeamsPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Zastępstwa]]]',
                        route: 'core-substitutions',
                        icon: 'far fa-fw fa-user-group',
                        auth: { all: ['SubstitutionsPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie zastępstwa]]]',
                                route: 'core-substitutions-add',
                                auth: { all: ['SubstitutionsPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja zastępstwa]]]',
                                route: 'core-substitutions-edit',
                                auth: { all: ['SubstitutionsPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    }
                ]
            },
            {
                name: '[[[Wyszukiwanie]]]',
                icon: 'far fa-fw fa-input-pipe',
                namespace: 'Ideo.Core.Common.Application.Permissions.Search',
                children: [
                    {
                        name: '[[[Lista indeksów]]]',
                        auth: { all: ['SearchPermissions@Access'], any: [] },
                        route: 'search-indices'
                    },
                    {
                        name: '[[[Słowa wykluczone]]]',
                        auth: { all: ['StopWordsPermissions@Access'], any: [] },
                        route: 'search-stopwords'                    },
                    {
                        name: '[[[Synonimy]]]',
                        auth: { all: ['SynonymsPermissions@Access'], any: [] },
                        route: 'search-synonyms'
                    }
                ]
            },
            {
                name: '[[[Ustawienia]]]',
                icon: 'far fa-fw fa-cog',
                children: [
                    {
                        name: '[[[Konta użytkowników]]]',
                        route: 'core-accounts',
                        icon: 'fas fa-fw fa-users',
                        auth: { all: ['AdminPermissions@Accounts'], any: [] }
                    },
                    {
                        name: '[[[Bezpieczeństwo]]]',
                        route: 'core-security',
                        icon: 'fas fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Security'], any: [] }
                    },
                    {
                        name: '[[[ReCaptcha]]]',
                        route: 'core-recaptcha',
                        icon: 'fas fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Recaptcha'], any: [] }
                    },
                    {
                        name: '[[[Poczta]]]',
                        icon: 'fa-solid fa-gears',
                        route: 'email-configuration',
                        auth: { all: ['EmailConfigurationsPermissions@View'], any: [] },
                        namespace: 'Ideo.Core.Workspace.Application.Permissions.Catalog',
                        children:[
                            {
                                name: '[[[Dodaj konfigurację poczty]]]',
                                route: 'email-configuration-add',
                                visible: false,
                            },
                            {
                                name: '[[[Edytuj konfigurację poczty]]]',
                                route: 'email-configuration-edit',
                                visible: false,
                            }
                        ]
                    },
                    {
                        name: '[[[Powiadomienia]]]',
                        route: 'core-notifications-templates',
                        icon: 'far fa-fw fa-ballot-check',
                        auth: { all: ['NotificationsPermissions@Access'], any: [] },
                        children: [
                            {
                                name: '[[[Lista powiadomień]]]',
                                route: 'core-notifications-templates',
                                auth: { all: ['NotificationsPermissions@Access'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Dodaj szablon]]]',
                                route: 'core-notifications-templates-add',
                                auth: { all: ['NotificationsPermissions@Access'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edytuj szablon]]]',
                                route: 'core-notifications-templates-edit',
                                auth: { all: ['NotificationsPermissions@Access'], any: [] },
                                visible: false,
                            }
                        ]
                    },
                    {
                        name: '[[[Źródła LDAP]]]',
                        route: 'core-ldap',
                        icon: 'fas fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@LdapConfig'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie źródła]]]',
                                route: 'core-ldap-add',
                                auth: { all: ['AdminPermissions@LdapConfig'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja źródła]]]',
                                route: 'core-ldap-edit',
                                auth: { all: ['AdminPermissions@LdapConfig'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Bramka SMS]]]',
                        route: 'core-sms',
                        icon: 'fas fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Sms'], any: [] }
                    },
                    {
                        name: '[[[Ustawienia sesji]]]',
                        route: 'core-session-settings',
                        icon: 'fas fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Session'], any: [] }
                    },
                    {
                        name: '[[[Paleta kolorów]]]',
                        route: 'core-colors',
                        icon: 'fas fa-fw fa-palette',
                        auth: { all: ['AdminPermissions@Colors'], any: [] }
                    },
                    {
                        name: '[[[Asystent AI]]]',
                        route: 'core-ai',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                    },
                    {
                        name: '[[[Tłumaczenia]]]',
                        route: 'core-translations',
                        icon: 'far fa-fw fa-subtitles',
                        auth: { all: ['AdminPermissions@Translations'], any: [] }
                    },
                    {
                        name: '[[[Asystent]]]',
                        route: 'core-ai-assistant-add',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Asystent]]]',
                        route: 'core-ai-assistant-edit',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Pliki Asystenta]]]',
                        route: 'core-ai-assistant-file-add',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Pliki Asystent]]]',
                        route: 'core-ai-assistant-file-edit',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Funkcje Asystenta]]]',
                        route: 'core-ai-assistant-function',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Funkcje Asystenta]]]',
                        route: 'core-ai-assistant-function-add',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Funkcje Asystent]]]',
                        route: 'core-ai-assistant-function-edit',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Monitoring]]]',
                        route: 'core-monitoring',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Monitoring'], any: [] }
                    },
                    {
                        name: '[[[Logi]]]',
                        route: 'core-logs-targets',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Logs'], any: [] },
                        children: [
                            {
                                name: '[[[Logi]]]',
                                route: 'core-logs-targets',
                                icon: 'far fa-fw fa-shield-alt',
                                auth: { all: ['AdminPermissions@Logs'], any: [] },
                                visible: false,
                                children: [
                                    {
                                        name: '[[[Dodanie celu]]]',
                                        route: 'core-logs-targets-add',
                                        auth: { all: ['AdminPermissions@Logs'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Edycja celu]]]',
                                        route: 'core-logs-targets-edit',
                                        auth: { all: ['AdminPermissions@Logs'], any: [] },
                                        visible: false
                                    }
                                ]
                            },
                            {
                                name: '[[[Logi]]]',
                                route: 'core-logs-rules',
                                icon: 'far fa-fw fa-shield-alt',
                                auth: { all: ['AdminPermissions@Logs'], any: [] },
                                visible: false,
                                children: [
                                    {
                                        name: '[[[Dodanie celu]]]',
                                        route: 'core-logs-rules-add',
                                        auth: { all: ['AdminPermissions@Logs'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Edycja celu]]]',
                                        route: 'core-logs-rules-edit',
                                        auth: { all: ['AdminPermissions@Logs'], any: [] },
                                        visible: false
                                    }
                                ]
                            }
                        ]
                    },

                ]
            },
            {
                name: '[[[Monitoring]]]',
                icon: 'fas fa-fw fa-analytics',
                children: [
                    {
                        name: '[[[Historia logowania]]]',
                        route: 'core-authlog',
                        icon: 'fas fa-fw fa-file-import',
                        auth: { all: ['AdminPermissions@Authlog'], any: [] }
                    },
                    {
                        name: '[[[Rejestr zmian]]]',
                        route: 'core-changelog',
                        icon: 'fas fa-fw fa-file-signature',
                        auth: { all: ['AdminPermissions@Changelog'], any: [] },
                        children: [
                            {
                                name: '[[[Szczegóły zdarzenia]]]',
                                route: 'core-changelog-diff',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Harmonogram zadań]]]',
                        route: 'core-scheduler',
                        icon: 'fas fa-fw fa-clock',
                        auth: { all: ['SchedulerPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Historia zadania]]]',
                                route: 'core-scheduler-history',
                                visible: false
                            },
                            {
                                name: '[[[Szczegóły zadania]]]',
                                route: 'core-scheduler-details',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Monitor kolejek]]]',
                        route: 'core-queues',
                        icon: 'fas fa-fw fa-clock',
                        auth: { all: ['MessagePermissions@View'], any: [] },
                        children: [
                            {
                                name: "[[[Szczegóły zadania]]]",
                                route: 'core-queues-details',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Historia zapytań AI]]]',
                        route: 'core-ai-history',
                        icon: 'far fa-fw fa-file-signature',
                        auth: { all: ['AdminPermissions@Ai'], any: [] },
                        children: [
                            {
                                name: '[[[Szczegóły zapytania]]]',
                                route: 'core-ai-history-details',
                                visible: false
                            }
                        ]
                    },
                ]
            },
        ]
    },
    {
        name: '[[[Design Studio]]] (β)',
        short: '[[[Studio]]]',
        icon: 'fa-light fa-circle-nodes', // fa-gear
        namespace: 'Ideo.Core.Common.Application.Permissions.Studio',
        children: [
            {
                name: '[[[Formularze]]]',
                icon: 'far fa-fw fa-table-layout',
                auth: { all: ['FormsPermissions@Access'], any: [] },
                route: 'studio-forms',
                children: [
                    {
                        name: '[[[Dodanie formularza]]]',
                        route: 'studio-forms-add',
                        auth: { all: [], any: ['FormsPermissions@Edit', 'FormsPermissions@EditAll'] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja formularza]]]',
                        route: 'studio-forms-edit',
                        auth: { all: [], any: ['FormsPermissions@Edit', 'FormsPermissions@EditAll'] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Komponenty]]]',
                icon: 'far fa-fw fa-grid-round-2-plus',
                auth: { all: ['ComponentsPermissions@Access'], any: [] },
                route: 'studio-components',
                children: [
                    {
                        name: '[[[Dodanie komponentu]]]',
                        route: 'studio-components-add',
                        auth: { all: ['ComponentsPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja komponentu]]]',
                        route: 'studio-components-edit',
                        auth: { all: ['ComponentsPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Źródła danych]]]',
                route: 'studio-datasources',
                icon: 'far fa-fw fa-database',
                auth: { all: ['DataSourcesPermissions@Access'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie źródła danych]]]',
                        route: 'studio-datasources-add',
                        auth: { all: ['DataSourcesPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja źródła danych]]]',
                        route: 'studio-datasources-edit',
                        auth: { all: ['DataSourcesPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Moduły]]]',
                icon: 'far fa-fw fa-layer-group',
                auth: { all: ['ModulesPermissions@Access'], any: [] },
                route: 'studio-modules',
                children: [
                    {
                        name: '[[[Dodanie modułu]]]',
                        route: 'studio-modules-add',
                        auth: { all: ['ModulesPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja modułu]]]',
                        route: 'studio-modules-edit',
                        auth: { all: ['ModulesPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[RESTful API]]]',
                icon: 'far fa-fw fa-brackets-curly',
                auth: { all: ['HeadlessPermissions@Access'], any: [] },
                route: 'studio-endpoints',
                children: [
                    {
                        name: '[[[Dodanie endpointu]]]',
                        route: 'studio-endpoints-add',
                        auth: { all: ['HeadlessPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja endpointu]]]',
                        route: 'studio-endpoints-edit',
                        auth: { all: ['HeadlessPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Webhooki]]]',
                icon: 'far fa-fw fa-webhook',
                auth: { all: ['WebhooksPermissions@Access'], any: [] },
                route: 'studio-webhooks',
                children: [
                    {
                        name: '[[[Dodanie webhooka]]]',
                        route: 'studio-webhooks-add',
                        auth: { all: ['WebhooksPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja webhooka]]]',
                        route: 'studio-webhooks-edit',
                        auth: { all: ['WebhooksPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Logi]]]',
                        route: 'studio-webhooks-logs',
                        auth: { all: ['WebhooksPermissions@Access'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Automatyzacja]]]',
                icon: 'far fa-fw fa-gears', // fa-braille
                auth: { all: ['AutomationPermissions@Access'], any: [] },
                url: '#',
                route: 'studio-automations',
                children: [
                    {
                        name: '[[[Dodanie scenariusza]]]',
                        route: 'studio-automations-add',
                        auth: { all: ['AutomationPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja scenariusza]]]',
                        route: 'studio-automations-edit',
                        auth: { all: ['AutomationPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            // {
            //     name: '[[[Dokumenty]]]',
            //     icon: 'far fa-fw fa-notebook',
            //     auth: { all: ['EntriesPermissions@Access'], any: [] },
            //     route: 'studio-entries',
            //     children: [
            //         {
            //             name: '[[[Dodanie dokumentu]]]',
            //             route: 'studio-entries-add',
            //             auth: { all: [], any: ['EntriesPermissions@Edit', 'EntriesPermissions@EditAll'] },
            //             visible: false
            //         },
            //         {
            //             name: '[[[Edycja dokumentu]]]',
            //             route: 'studio-entries-edit',
            //             auth: { all: [], any: ['EntriesPermissions@Edit', 'EntriesPermissions@EditAll'] },
            //             visible: false
            //         }
            //     ]
            // }
        ]
    },
    {
        name: '[[[Moduły]]]',
        short: '[[[Moduły]]]',
        route: 'studio-docs',
        preventCloseMenu: true,
        icon: 'fa-light fa-layer-group',
        namespace: 'Ideo.Core.Common.Application.Permissions.Studio',
        auth: { all: ['ModulesPermissions@Access'], any: [] }
    },
    {
        name: '[[[Repozytorium plików]]]',
        short: '[[[Pliki]]]',
        route: 'filemanager',
        preventCloseMenu: true,
        icon: 'fa-light fa-folder-closed',
        namespace: 'Ideo.Core.Common.Application.Permissions.Storage',
        auth: { all: ['StoragePermissions@Access'], any: [] }
    },
    {
        name: '[[[Kosz]]]',
        short: '[[[Kosz]]]',
        route: 'trash',
        preventCloseMenu: true,
        icon: 'fa-light fa-trash',
        namespace: 'Ideo.Core.Common.Application.Permissions.Core',
        auth: { all: ['TrashPermissions@View'], any: [] },
        children: [
            {
                name: '[[[Grupa]]]',
                route: 'trash-group',
                visible: false
            }
        ]
    }
];
