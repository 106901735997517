import { axios } from "@/plugins/axios";
import { Statement } from "@/helpers/Interfaces";

export default class HomeService
{

    public static async getAvailableWidgets(filter?: FormFilter): Promise<Array<APIWidget>>
    {
        return (
            await axios.get(`admin/dashboard/widgets`, {
                params: filter,
            })
        ).data;
    }

    public static async getSettings(): Promise<WidgetSettings>
    {
        return (await axios.get(`admin/dashboard/config`)).data;
    }

    public static async saveSettings(form: any): Promise<Statement>
    {
        return (await axios.post(`admin/dashboard/config`, form)).data;
    }

    public static async getWidgetUserConfig(publicId: string): Promise<WidgetModel>
    {
        return (await axios.get(`admin/dashboard/widget/${publicId}`)).data;
    }

    public static async updateWidgetUserConfig(publicId: string, form: WidgetModel): Promise<Statement>
    {
        return (await axios.post(`admin/dashboard/widget/${publicId}`, form)).data;
    }
}

export interface GridItemAttributes {
    id: string;
    "gs-id": string;
    "gs-x": number;
    "gs-y": number;
    "gs-w": number;
    "gs-h": number;
    "gs-no-resize"?: boolean;
}

export interface Widget {
    publicId: string;
    name: string;
    w: number;
    h: number;
    x?: number;
    y?: number;
    config?: WidgetModel;
}

export interface WidgetSettings {
    config: Array<{ publicId: string; x: number; y: number }>;
    publicId: string;
}

export interface WidgetModel {
    description: string;
    height: string;
    name: string;
    type: string;
    width: string;
    sourceConfig: Record<string, any> | string;
}

export interface APIWidget extends WidgetModel {
    publicId: string;
}

export interface FormFilter {
    search: string;
}

export enum WidgetTypeEnum {
    ActiveSubstitutions = "ActiveSubstitutions",
    AiUsage = "AiUsage"
}
