/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EmptyLayout from '@/views/layouts/EmptyLayout.vue';

export default [
    {
        //Programy lojalnościowe
        path: '',
        name: '',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'programs',
                name: 'programs',
                meta: { auth: true },
                component: () => import('@/modules/catalog/programs/views/Programs.vue')
            },
            {
                path: 'programs/add',
                name: 'programs-add',
                meta: { auth: true },
                component: () => import('@/modules/catalog/programs/views/ProgramForm.vue')
            },
            {
                path: 'programs/:id(\\d+)',
                name: 'programs-edit',
                meta: { auth: true },
                component: () => import('@/modules/catalog/programs/views/ProgramForm.vue')
            },
        ]
    },
    {
        //Dostawcy
        path: '',
        name: '',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'suppliers',
                name: 'suppliers',
                meta: { auth: true },
                component: () => import('@/modules/catalog/suppliers/views/Suppliers.vue')
            },
            {
                path: 'suppliers/add',
                name: 'suppliers-add',
                meta: { auth: true },
                component: () => import('@/modules/catalog/suppliers/views/SupplierForm.vue')
            },
            {
                path: 'suppliers/:id(\\d+)',
                name: 'suppliers-edit',
                meta: { auth: true },
                component: () => import('@/modules/catalog/suppliers/views/SupplierForm.vue')
            },
        ]
    },
    {
        //Katalog kategorie
        path: '',
        name: '',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'product-categories',
                name: 'product-categories',
                meta: { auth: true },
                component: () => import('@/modules/catalog/productCategories/views/ProductCategories.vue')
            },
            {
                path: 'product-categories/add',
                name: 'product-categories-add',
                meta: { auth: true },
                component: () => import('@/modules/catalog/productCategories/views/ProductCategoriesForm.vue')
            },
            {
                path: 'product-categories/:id(\\d+)',
                name: 'product-categories-edit',
                meta: { auth: true },
                component: () => import('@/modules/catalog/productCategories/views/ProductCategoriesForm.vue')
            },
            {
                path: 'product-categories/sort',
                name: 'product-categories-sort',
                meta: { auth: true },
                component: () => import('@/modules/catalog/productCategories/views/ProductCategoriesSort.vue')
            },
            {
                path: 'product-categories/map/:integrationType',
                name: 'product-categories-map',
                meta: { auth: true },
                component: () => import('@/modules/catalog/productCategories/views/ProductCategoriesMapList.vue')
            },
            {
                path: 'product-categories/map/:integrationType/:id',
                name: 'product-categories-map-edit',
                meta: { auth: true },
                component: () => import('@/modules/catalog/productCategories/views/ProductCategoriesMapForm.vue')
            },
        ]
    },
    {
        //Karty Visa
        path: '',
        name: '',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            // Karty - zamówienia
            {
                path: 'prepaid-card/orders',
                name: 'prepaid-card-orders',
                meta: { auth: true },
                component: () => import('@/modules/catalog/prepaidCards/views/PrepaidCardOrders.vue')
            },
            {
                path: 'prepaid-card/orders/add',
                name: 'prepaid-card-orders-add',
                meta: { auth: true },
                component: () => import('@/modules/catalog/prepaidCards/views/PrepaidCardOrdersAdd.vue')
            },
            {
                path: 'prepaid-card/orders/:id(\\d+)',
                name: 'prepaid-card-orders-edit',
                meta: { auth: true },
                component: () => import('@/modules/catalog/prepaidCards/views/PrepaidCardOrdersForm.vue')
            },
            {
                path: 'prepaid-card/orders/import',
                name: 'prepaid-card-orders-import',
                meta: { auth: true },
                component: () => import('@/modules/catalog/prepaidCards/views/PrepaidCardOrdersImport.vue')
            },
            {
                path: 'prepaid-card/orders/assign',
                name: 'prepaid-card-assign-orders-to-cards-import',
                meta: { auth: true },
                component: () => import('@/modules/catalog/prepaidCards/views/PrepaidCardAssignOrderToCardImport.vue')
            },
            // Karty - zamówienia --- koniec
            // Karty - historia dyspozycji
            {
                path: 'prepaid-card/acceptance-logs',
                name: 'prepaid-card-acceptance-logs',
                meta: { auth: true },
                component: () => import('@/modules/catalog/prepaidCards/views/PrepaidCardAcceptanceLogs.vue')
            },
            {
                path: 'prepaid-card/acceptance-logs/:id(\\d+)',
                name: 'prepaid-card-acceptance-logs-details',
                meta: { auth: true },
                component: () => import('@/modules/catalog/prepaidCards/views/PrepaidCardAcceptanceLogsDetails.vue')
            },
            // Karty - historia dyspozycji --- koniec
            // Karty - rozliczenia
            {
                path: 'prepaid-card/settlements',
                name: 'prepaid-card-settlements',
                meta: { auth: true },
                component: () => import('@/modules/catalog/prepaidCards/views/PrepaidCardSettlements.vue')
            },
            {
                path: 'prepaid-card/settlements/add',
                name: 'prepaid-card-settlements-add',
                meta: { auth: true },
                component: () => import('@/modules/catalog/prepaidCards/views/PrepaidCardSettlementsForm.vue')
            },
            {
                path: 'prepaid-card/settlements/:id(\\d+)',
                name: 'prepaid-card-settlements-edit',
                meta: { auth: true },
                component: () => import('@/modules/catalog/prepaidCards/views/PrepaidCardSettlementsForm.vue')
            },
            // Koniec Karty - rozliczenia
            // Karty - karty
            {
                path: 'prepaid-card/numbers',
                name: 'prepaid-card-numbers',
                meta: { auth: true },
                component: () => import('@/modules/catalog/prepaidCards/views/PrepaidCardNumbers.vue')
            },
            {
                path: 'prepaid-card/numbers/add',
                name: 'prepaid-card-numbers-add',
                meta: { auth: true },
                component: () => import('@/modules/catalog/prepaidCards/views/PrepaidCardNumbersForm.vue')
            },
            {
                path: 'prepaid-card/numbers/:id(\\d+)',
                name: 'prepaid-card-numbers-edit',
                meta: { auth: true },
                component: () => import('@/modules/catalog/prepaidCards/views/PrepaidCardNumbersForm.vue')
            },
            {
                path: 'prepaid-card/numbers/import',
                name: 'prepaid-card-numbers-import',
                meta: { auth: true },
                component: () => import('@/modules/catalog/prepaidCards/views/PrepaidCardNumbersImport.vue')
            },
            // koniec Karty - karty
        ]
    },
    {
        //Bliki
        path: '',
        name: '',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            // Bliki - zamówienia
            {
                path: 'blik/orders',
                name: 'blik-orders',
                meta: { auth: true },
                component: () => import('@/modules/catalog/blik/views/BlikOrders.vue')
            },
            {
                path: 'blik/orders/add',
                name: 'blik-orders-add',
                meta: { auth: true },
                component: () => import('@/modules/catalog/blik/views/BlikOrdersAdd.vue')
            },
            {
                path: 'blik/orders/:id(\\d+)',
                name: 'blik-orders-edit',
                meta: { auth: true },
                component: () => import('@/modules/catalog/blik/views/BlikOrdersAdd.vue')
            },
            {
                path: 'blik/orders/import',
                name: 'blik-orders-import',
                meta: { auth: true },
                component: () => import('@/modules/catalog/blik/views/BlikOrdersImport.vue')
            },
            // Bliki - zamówienia --- koniec
            // Bliki - rozliczenia
            {
                path: 'blik/settlements',
                name: 'blik-settlements',
                meta: { auth: true },
                component: () => import('@/modules/catalog/blik/views/BlikSettlements.vue')
            },
            {
                path: 'blik/settlements/add',
                name: 'blik-settlements-add',
                meta: { auth: true },
                component: () => import('@/modules/catalog/blik/views/BlikSettlementsForm.vue')
            },
            {
                path: 'blik/settlements/:id(\\d+)',
                name: 'blik-settlements-edit',
                meta: { auth: true },
                component: () => import('@/modules/catalog/blik/views/BlikSettlementsForm.vue')
            },
            // Bliki - rozliczenia koniec
        ]
    },
    {
        //Katalog produkty
        path: '',
        name: '',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'products',
                name: 'products',
                meta: { auth: true },
                component: () => import('@/modules/catalog/products/views/Products.vue')
            },
            {
                path: 'products/add',
                name: 'products-add',
                meta: { auth: true },
                component: () => import('@/modules/catalog/products/views/ProductsForm.vue')
            },
            {
                path: 'products/:id(\\d+)',
                name: 'products-edit',
                meta: { auth: true },
                component: () => import('@/modules/catalog/products/views/ProductsForm.vue')
            },
            {
                path: 'products/edit/selected',
                name: 'products-edit-selected',
                meta: { auth: true },
                component: () => import('@/modules/catalog/products/views/ProductsEditSelected.vue')
            },
            {
                path: 'products/import',
                name: 'products-import',
                meta: { auth: true },
                component: () => import('@/modules/catalog/products/views/ProductsImport.vue')
            },
        ]
    },
    {
        //Dostawcy
        path: '',
        name: '',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'banners',
                name: 'banners',
                meta: { auth: true },
                component: () => import('@/modules/catalog/banners/views/Banners.vue')
            },
            {
                path: 'banners/add',
                name: 'banners-add',
                meta: { auth: true },
                component: () => import('@/modules/catalog/banners/views/BannersForm.vue')
            },
            {
                path: 'banners/:id(\\d+)',
                name: 'banners-edit',
                meta: { auth: true },
                component: () => import('@/modules/catalog/banners/views/BannersForm.vue')
            },
        ]
    },
    {
        //Katalog zamówienia
        path: '',
        name: '',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'orders',
                name: 'orders',
                meta: { auth: true },
                component: () => import('@/modules/catalog/orders/views/Orders.vue')
            },
            {
                path: 'orders/add',
                name: 'orders-add',
                meta: { auth: true },
                component: () => import('@/modules/catalog/orders/views/OrdersForm.vue')
            },
            {
                path: 'orders/:id(\\d+)',
                name: 'orders-edit',
                meta: { auth: true },
                component: () => import('@/modules/catalog/orders/views/OrdersEditForm.vue')
            },
            {
                path: 'orders/import/statuses',
                name: 'orders-import-statuses',
                meta: { auth: true },
                component: () => import('@/modules/catalog/orders/views/OrdersImportStatuses.vue')
            },
            {
                path: 'orders/edit/selected',
                name: 'orders-edit-selected',
                meta: { auth: true },
                component: () => import('@/modules/catalog/orders/views/OrdersEditSelected.vue')
            },
            {
                path: 'orders/statistics',
                name: 'orders-statistics',
                meta: { auth: true },
                component: () => import('@/modules/catalog/orders/views/OrdersStatistics.vue')
            },
        ]
    },
    {
        //Reklamacje
        path: '',
        name: '',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'complaints',
                name: 'complaints',
                meta: { auth: true },
                component: () => import('@/modules/catalog/complaints/views/Complaints.vue')
            },
            {
                path: 'complaints/add',
                name: 'complaints-add',
                meta: { auth: true },
                component: () => import('@/modules/catalog/complaints/views/ComplaintsForm.vue')
            },
            {
                path: 'complaints/:id(\\d+)',
                name: 'complaints-edit',
                meta: { auth: true },
                component: () => import('@/modules/catalog/complaints/views/ComplaintsEditForm.vue')
            },
            {
                path: 'complaints/:list',
                name: 'complaints-list',
                meta: { auth: true },
                component: () => import('@/modules/catalog/complaints/views/Complaints.vue')
            },
            {
                path: 'complaints/multiple',
                name: 'complaints-edit-multiple',
                meta: { auth: true },
                component: () => import('@/modules/catalog/complaints/views/ComplaintsEditMultiple.vue')
            },
        ]
    },
    {
        //Podsumowanie programów
        path: '',
        name: '',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'programs-summary',
                name: 'programs-summary',
                meta: { auth: true },
                component: () => import('@/modules/catalog/programsSummary/views/ProgramsSummary.vue')
            },
        ]
    },
    {
        //Podsumowanie programów
        path: 'report',
        name: 'report',
        meta: { auth: true },
        component: () => import('@/modules/catalog/reports/views/Reports.vue'),
    },
    {
        //Infolinia
        path: '',
        name: '',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'phone-connections',
                name: 'phone-connections',
                meta: { auth: true },
                component: () => import('@/modules/catalog/phoneConnections/views/PhoneConnections.vue')
            },
            {
                path: 'phone-connections/add',
                name: 'phone-connections-add',
                meta: { auth: true },
                component: () => import('@/modules/catalog/phoneConnections/views/PhoneConnectionsForm.vue')
            },
            {
                path: 'phone-connections/:id(\\d+)',
                name: 'phone-connections-edit',
                meta: { auth: true },
                component: () => import('@/modules/catalog/phoneConnections/views/PhoneConnectionsForm.vue')
            },
            {
                path: 'phone-connections/:list',
                name: 'phone-connections-list',
                meta: { auth: true },
                component: () => import('@/modules/catalog/phoneConnections/views/PhoneConnections.vue')
            },
        ]
    },
    {
        //Drukarki
        path: '',
        name: '',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'printers',
                name: 'printers',
                meta: { auth: true },
                component: () => import('@/modules/catalog/printers/views/PrinterForm.vue')
            },
            {
                path: 'printers/codes',
                name: 'printers-codes',
                meta: { auth: true },
                component: () => import('@/modules/catalog/printers/views/PrinterErrorCodes.vue')
            },
        ]
    },
];
