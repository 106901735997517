<script lang="ts" setup>
import { ref, computed, onBeforeMount, onMounted } from 'vue';
import { DoughnutChart, useDoughnutChart } from 'vue-chart-3';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import { WidgetModel } from '../../services/HomeService';
import AiService from '@/modules/core/common/services/AiService';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';

Chart.register(...registerables);

const { $alert } = useAlerts();
const { $t } = useLocalization();

defineOptions({
    name: "AiUsageWidget",
    components: {
        DoughnutChart
    }
});

interface AiUsage
{
    limitInDollars: number;
    remainingLimitInDollars: number;
    sumPriceInDollars: number;
}

interface ChartVisibility
{
    header: boolean;
    legend: boolean;
}

const props = defineProps({
  "config": null,
  "publicId": null,
  "width": null,
  "height": null
});

const loaded = ref(false);
const data = ref<AiUsage>();

const chartOptions = computed<ChartOptions<"doughnut">>(() => ({
    responsive: true,
    maintainAspectRatio: false,
    scales: {},
    plugins: {
        legend: {
            display: chartVisibility.value.legend,
            position: 'bottom',
            fullSize: true,
            title: {
                display: true,
            }
        },
        tooltip: {
            callbacks: {
                label: function(context)
                {
                    return `${context.label}: $${context.parsed.toFixed(2)}`;
                }
            }
        },
        title: {
            display: chartVisibility.value.header,
            text: $t('[[[Dostępny limit]]]'),
        },
        subtitle: {
            display: chartVisibility.value.header,
            text: `$${data.value?.remainingLimitInDollars.toFixed(2)}$ / $${data.value?.limitInDollars}`,
            padding: {
                bottom: 16
            }
        }
    },
}));

const chartColors = ref<string[]>([]);
const chartVisibility = ref<ChartVisibility>({
    header: true,
    legend: true
});

const chartData = computed<ChartData<"doughnut">>(() => ({
    labels: [$t('[[[Dostępne]]]'), $t('[[[Wykorzystane]]]')],
    datasets: [
        {
            data: [data.value?.remainingLimitInDollars, data.value?.sumPriceInDollars],
            backgroundColor: chartColors.value
        },
    ],
}));

const { doughnutChartProps } = useDoughnutChart({
    chartData: chartData,
    options: chartOptions,
});

const loadData = async (): Promise<void> =>
{
    try
    {
        loaded.value = false;
        data.value = await AiService.getOpenAiUsage();
    }
    catch (ex: any)
    {
        $alert.error(ex.message || $t('[[[Nie udało się załadować widgetu AI.]]]'));
    }
    finally
    {
        loaded.value = true;
    }
};

const initChartOptions = (form?: any) =>
{
    const height = form ? parseInt(form.h) : props.height;
    const width = form ? parseInt(form.w) : props.width;

    if (width === 2)
    {
        chartVisibility.value = {
            header: false,
            legend: false
        };
    }

    if (height === 2)
    {
        chartVisibility.value.header = false;
    }
};

onBeforeMount(async (): Promise<void> =>
{
    initChartOptions();
    await loadData();
});

onMounted(() =>
{
    const style = getComputedStyle(document.body);

    chartColors.value = [
        style.getPropertyValue('--bs-primary'),
        style.getPropertyValue('--bs-gray-300')
    ];
});
</script>

<template>
    <div class="ai-usage" v-if="loaded">
        <DoughnutChart v-bind="doughnutChartProps" />
    </div>
</template>

<style lang="scss">
.ai-usage {
    height: calc(100% - 8px);
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
        height: 100%;
        flex: 1;
    }
}
</style>
