<template>
    <IdeoModal centered :title="title" ref="widget-settings-modal" @hidden="clearForm">
        <template #default>
            <form id="version-modal-form" @keydown="form.$errors.clear(($event.target as HTMLInputElement).name)" @submit.prevent="onSubmit">
                <!-- Nazwa -->
                <IdeoFormGroup
                    :label="$t('[[[Nazwa]]]')"
                    :invalid-feedback="form.$errors.first('name')"
                    :state="form.$errors.state('name')"
                    required
                >
                    <IdeoFormInput v-model="form.name" :placeholder="$t('[[[Nazwa]]]')" type="text" name="name" disabled />
                </IdeoFormGroup>

                <!-- Opis -->
                <IdeoFormGroup
                    :label="$t('[[[Opis]]]')"
                    :invalid-feedback="form.$errors.first('description')"
                    :state="form.$errors.state('description')"
                >
                    <IdeoFormTextarea v-model="form.description" :placeholder="$t('[[[Opis]]]')" name="description" rows="4" disabled />
                </IdeoFormGroup>

                <!-- Liczba wyników na stronie -->
                <IdeoFormGroup
                    v-if="isPageSize"
                    :label="$t('[[[Liczba wyników na stronie]]]')"
                    :invalid-feedback="form.$errors.first('pageSize')"
                    :state="form.$errors.state('pageSize')"
                    required
                >
                    <IdeoFormInput v-model="(form.sourceConfig as Record<string, any>).pageSize" type="number" name="pageSize" />
                </IdeoFormGroup>

                <div class="row">
                    <div class="col-sm-6">
                        <!-- Szerokość -->
                        <IdeoFormGroup
                            :label="$t('[[[Szerokość]]]')"
                            :invalid-feedback="form.$errors.first('width')"
                            :state="form.$errors.state('width')"
                            required
                        >
                            <IdeoFormSelect v-model="form.width" name="width" :options="widthOptions" @update:modelValue="form.$errors.clear('width')" />
                        </IdeoFormGroup>
                    </div>
                    <div class="col-sm-6">
                        <!-- Wysokość -->
                        <IdeoFormGroup
                            :label="$t('[[[Wysokość]]]')"
                            :invalid-feedback="form.$errors.first('height')"
                            :state="form.$errors.state('height')"
                            required
                        >
                            <IdeoFormSelect v-model="form.height" name="height" :options="heightOptions" @update:modelValue="form.$errors.clear('height')" />
                        </IdeoFormGroup>
                    </div>
                </div>
            </form>
        </template>
        <template #modal-footer="{ cancel }">
            <ActionButton type="button" icon="fa fa-ban" variant="default" :text="$t('[[[Anuluj]]]')" @click="cancel" />
            <ActionButton submit variant="success" :icon="form.active() ? 'fas fa-check' : 'fas fa-spinner fa-spin'" form="version-modal-form" :text="$t('[[[Zapisz]]]')" :disabled="!form.active()" />
        </template>
    </IdeoModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Ref } from "@/helpers/Decorators";
import { Form } from "@/helpers/Form";
import HomeService, { WidgetModel } from "@/modules/core/home/services/HomeService";
import { cloneDeep } from "lodash";
import { Option } from '@/helpers/Interfaces';
import { WIDTH_OPTIONS, HEIGHT_OPTIONS } from "@/modules/core/home/utils";
import IdeoModal from "@/components/ideo/modal/IdeoModal.vue";

@Options({
    name: "WidgetSettingsModal",
    emits: ["update-widget"],
})
export default class WidgetSettingsModal extends Vue
{
    @Ref("widget-settings-modal")
    public modal: () => IdeoModal;

    public form = Form.create<WidgetModel>({
        height: null,
        name: "",
        description: "",
        type: "",
        width: null,
        sourceConfig: null,
    });

    public publicId: string = "";
    public name: string = "";

    public get isPageSize(): boolean
    {
        return this.form.type === "ActiveSubstitutions"
                && this.form.sourceConfig
                && typeof this.form.sourceConfig === "object"
                && 'pageSize' in this.form.sourceConfig;
    }

    public get title(): string
    {
        return `${this.form.name} - ${this.$t("[[[ustawienia widgetu]]]")}`;
    }

    public get widthOptions(): Option<string>[]
    {
        return WIDTH_OPTIONS;
    }

    public get heightOptions(): Option<string>[]
    {
        return HEIGHT_OPTIONS;
    }

    public mounted(): void
    {
        this.$events.$on("widget-settings-modal", this.handleOpen);
    }

    public unmounted(): void
    {
        this.$events.$off("widget-settings-modal", this.handleOpen);
    }

    public async loadData(): Promise<void>
    {
        try
        {
            const widget = (await HomeService.getWidgetUserConfig(this.publicId));

            this.form.withData(widget);
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => this.form.$errors.record(ex.data.errors),
            });
        }
    }

    public async handleOpen({ publicId }: { publicId: string }): Promise<void>
    {
        this.publicId = publicId;
        await this.loadData();
        this.modal().show();
    }

    public handleClose(): void
    {
        this.modal().hide();
        this.clearForm();
    }

    public clearForm(): void
    {
        this.form.clear();
    }

    public async onSubmit(): Promise<void>
    {
        try
        {
            const form = cloneDeep(this.form.data()) as any;

            form.sourceConfig = JSON.stringify(form.sourceConfig);
            await HomeService.updateWidgetUserConfig(this.publicId, form);
            this.$alert.success(this.$t("[[[Zmieniono ustawienia widgetu]]]"));
            this.$emit("update-widget", form, this.publicId);
            this.handleClose();
        }
        catch (error)
        {
            this.handleException(error, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => this.form.$errors.record(ex.data.errors),
            });
        }
    }
}
</script>
