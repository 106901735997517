export default {
    "Project-Id-Version": "",
    "POT-Creation-Date": "2023-01-24T09:45:20.429Z",
    "MIME-Version": "1.0",
    "Content-Type": "text/plain; charset=utf-8",
    "Content-Transfer-Encoding": "8bit",
    "X-Generator": "Edito",
    "PO-Revision-Date": "2023-01-24T09:45:20.546Z",
    "Last-Translator": "",
    "Language-Team": "",
    "Language": "en-US",
    "Messages": {
        "%0 znaków": "%0 characters",
        "1 godzina": "1 hour",
        "10 minut": "10 minutes",
        "12 godzin": "12 hours",
        "15 minut": "15 minutes",
        "24 godziny": "24 hours",
        "3 godziny": "3 hours",
        "30 minut": "30 minutes",
        "5 minut": "5 minutes",
        "6 godzin": "6 hours",
        "Aby dodawać członków zespołu musisz najpierw zapisać formularz.": "To add team members, you have to save the form.",
        "Administracja": "Administration",
        "Administracja - Kraje": "Administration - Countries",
        "Administracja - Miasta": "Administration - Cities",
        "Administracja - Role": "Administration - Roles",
        "Administracja - Stanowiska pracy": "Administration - Positions",
        "Administracja - Użytkownicy": "Administration - Users",
        "Administracja - Województwa": "Administration - Voivodships",
        "Administracja - Zespoły": "Administration - Teams",
        "Administratorzy": "Administrators",
        "Adres": "Address",
        "Adres e-mail potwierdzony": "The email address has been confirmed",
        "Adres email": "Email address",
        "Adres email nadawcy": "Sender's email address",
        "Adres IP": "IP address",
        "Adres serwera": "Server address",
        "Adres URL": "URL",
        "Akceptacja": "Acceptance",
        "Akceptuj": "Accept",
        "Akcja": "Action",
        "Akcje": "Actions",
        "Aktualizacja: {0}": "",
        "Aktualizuj": "Update",
        "Aktualne hasło": "Current password",
        "Aktualne urządzenie": "Current device",
        "Aktywne": "Active",
        "Aktywne kanały komunikacji": "Active communication channels",
        "Angielski": "English",
        "Ankieta": "Survey",
        "Anuluj": "Cancel",
        "Autor": "Author",
        "Azure AD": "Azure AD",
        "Bardzo dobrze": "Very good",
        "Bardzo niezadowolony": "Very unsatisfied",
        "Bardzo zadowolony": "Very satisfied",
        "Bardzo źle": "Very bad",
        "Baza danych": "Database",
        "Baza danych tego klienta jest nieaktualna. Uruchom aktualizację za pomocą przycisku na dole formularza.": "This customer's database is out of date. Install an update with the button at the bottom of the form.",
        "Bazy danych": "Database",
        "Bezpieczeństwo": "Safety",
        "Bezpieczne połączenie SSL": "Secure SSL connection",
        "Blokada konta": "Account blocked",
        "Błąd 404. Nie znaleziono żądanego zasobu.": "Error 404. The requested resource was not found.",
        "Błędne": "Incorrect",
        "Brak": "Lack",
        "Brak aktywnego filtru": "No active filter",
        "Brak działu nadrzędnego": "No parent section",
        "Brak kluczy": "No keys",
        "Brak nowych powiadomień.": "No new notifications.",
        "Brak odpowiedzi": "No response",
        "Brak wersji": "No version",
        "Brak wyników": "No results",
        "Checkbox": "Checkbox",
        "Classic mode": "Classic mode",
        "Co 10 dni": "",
        "Co 10 minut": "",
        "Co 12 godzin": "",
        "Co 15 minut": "",
        "Co 2 godziny": "",
        "Co 2 minuty": "",
        "Co 2 tygodnie": "",
        "Co 20 minut": "",
        "Co 3 godziny": "",
        "co 3 miesiące": "every 3 months",
        "Co 3 miesiące": "",
        "co 30 dni": "every 30 days",
        "Co 30 minut": "",
        "Co 4 miesiące": "",
        "Co 5 dni": "",
        "Co 5 minut": "",
        "Co 6 godzin": "",
        "co 6 miesięcy": "every 6 months",
        "Co drugi dzień": "",
        "Co drugi miesiąc": "",
        "Co godzinę": "Every hour",
        "Co miesiąc": "Every month",
        "Co minutę": "",
        "Co pół roku": "",
        "co rok": "every year",
        "Co tydzień": "Every week",
        "Codziennie": "Every day",
        "Coś poszło nie tak": "Something went wrong",
        "Cron": "Cron",
        "Cyfry": "Digits",
        "Czas": "Time",
        "Czas pomiędzy": "Time between",
        "Czas trwania blokady": "Account blocking time",
        "Czas uruchomienia": "",
        "Czerwiec": "June",
        "Członkowie zespołu": "Team members",
        "Cztery kolumny": "Four columns",
        "Dalej": "Next",
        "Dane formularza są przechowywane w obiekcie Entry.": "The form data is stored in the Entry object.",
        "Dane osobowe": "Personal data",
        "Dark mode": "Dark mode",
        "Data": "Date",
        "Data do": "Date to",
        "Data dodania": "Adding date",
        "Data i czas": "Date and time",
        "Data modyfikacji": "Modification date",
        "Data od": "Date from",
        "Data opuszczenia zespołu": "",
        "Data rejestracji": "Registration date",
        "Data rozpoczęcia": "Start date",
        "Data uruchomienia": "",
        "Data usunięcia": "Deletion date",
        "Data utworzenia": "Creation date",
        "Data utworzenia wersji": "Version creation date",
        "Data użycia": "",
        "Data wygaśnięcia": "",
        "Data wylogowania": "Logout date",
        "Data zakończenia": "End date",
        "Data zakończenia blokady konta": "Account block end date",
        "Data zalogowania": "Login date",
        "Data zdarzenia": "Event date",
        "Default mode": "Default mode",
        "Definicja formularza znajduje się w obiekcie Form.": "The form definition is in the Form object.",
        "Dni": "",
        "do": "to",
        "Do akceptacji": "To be accepted",
        "do czasu ręcznego odblokowania": "until manually unlocked",
        "Dobrze": "Good",
        "Dodaj": "Add",
        "Dodaj dni": "Add days",
        "Dodaj do zespołu": "",
        "Dodaj dział": "Add section",
        "Dodaj formularz": "Add form",
        "Dodaj kategorię": "Add category",
        "Dodaj klienta": "Add client",
        "Dodaj rolę": "Add a role",
        "Dodaj serwer": "Add server",
        "Dodaj shortcode": "",
        "Dodaj stronę": "Add page",
        "Dodaj szablon": "Add template",
        "Dodaj użytkownika": "Add user",
        "Dodaj wpis": "Add entry",
        "Dodaj zastępstwo": "Add a replacement",
        "Dodaj zespół": "Add team",
        "Dodaj źródło": "Add source",
        "dodane i edytowane przeze mnie": "",
        "Dodane przeze mnie": "Added by me",
        "Dodanie": "Adding",
        "Dodanie działu": "Add section",
        "Dodanie formularza": "Add form",
        "Dodanie klienta": "Add client",
        "Dodanie roli": "Add role",
        "Dodanie serwera": "Add server",
        "Dodanie użytkownika": "Add user",
        "Dodanie zastępstwa": "Add replacement",
        "Dodanie zespołu": "Add team",
        "Dodanie źródła danych": "Add data source",
        "Dodanych plików %0 (limit wynosi %1).": "Added files %0 (limit is %1).",
        "Dodawanie": "Adding",
        "Dokument %0": "Document %0",
        "Domyślna wartość": "Default value",
        "Dopuść brak odpowiedzi": "Allow lack of response",
        "Dostęp niezdefiniowany": "Undefined access",
        "Dostęp przyznany": "Access granted",
        "Dostęp zabroniony": "Access denied",
        "Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk \"Aktualizuj\". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.": "A new update is available. Please save the work progress and click the \"Update\" button. You can also accept the update by refreshing the browser. Noo update may result in errors.",
        "Dowolny": "Any",
        "Dozwolone rozszerzenia": "Allowed extensions",
        "Drugie imię": "Middle name",
        "Dwie kolumny": "Two columns",
        "Dział aktywny": "Active section",
        "Dział nadrzędny": "Parent section",
        "Dział został dodany.": "The section has been added.",
        "Dział został zaktualizowany.": "The section has been updated.",
        "Dzielenie": "Division",
        "E-mail": "E-mail",
        "Edycja działu": "Edit section",
        "Edycja formularza": "Edit form",
        "Edycja klienta": "Edit client",
        "Edycja roli": "Edit role",
        "Edycja serwera": "Edit server",
        "Edycja użytkownika": "Edit user",
        "Edycja zastępstwa": "Edit substitution",
        "Edycja zespołu": "Edit team",
        "Edycja źródła danych": "Edit data source",
        "Edytowana wersja": "Edited version",
        "Edytowane mogą być tylko szablony włączonych i obsługiwanych kanałów.": "Only templates for enabled and supported channels can be edited.",
        "Edytuj": "Edit",
        "Edytuj dział": "Edit section",
        "Edytuj obraz": "Edit image",
        "Edytuj szablon": "Edit template",
        "Edytuj uprawnienia": "Edit permissions",
        "Edytuj wpis": "Edit post",
        "Element został przywrócony.": "The element has been restored.",
        "Element został usunięty.": "The element has been deleted.",
        "Email": "E-mail",
        "Etykieta": "Tag",
        "Etykiety": "Tags",
        "Filtr globalny": "Global filter",
        "Filtr prywatny": "Private filter",
        "Filtr został usunięty": "Filter has been removed",
        "Filtr został zapisany": "Filter has been saved",
        "Filtruj": "Filter",
        "Filtry": "Filters",
        "Filtry globalne": "Global filters",
        "Filtry systemowe": "System filters",
        "Formularz": "Modules / Contact form",
        "Formularz zarchiwizowany": "Archived form",
        "Formularz został dodany.": "The form has been added.",
        "Formularz został zaktualizowany.": "The form has been updated.",
        "Formularze": "Forms",
        "Godziny": "",
        "Grudzień": "December",
        "Grupa": "Group",
        "Harmonogram zadań": "Task scheduler",
        "Harmonogram został zmieniony": "",
        "Hasło": "Password",
        "Hasło (Klucz ukryty)": "Password (Hidden Key)",
        "Hasło musi zawierać co najmniej jeden znak specjalny np.: ~ @ # $ % ^ & *": "The password must contain at least one special character, e.g.: ~ @ $ % ^ & *",
        "Hasło użytkownika serwisowego": "Maintenace user password",
        "Hasło zostało zmienione. Zaloguj się ponownie": "The password has been changed. Sign in again.",
        "Historia logowania": "Login history",
        "Historia zadania": "Task history",
        "Host": "Host",
        "Host dodatkowy": "Secondary host",
        "Host główny": "Main host",
        "Id": "Id",
        "ID": "ID",
        "ID lub typ zadania": "",
        "Id modelu: %0": "Model Id: %0",
        "ID obiektu": "Object ID",
        "Id sesji": "Session Id",
        "ID zadania": "",
        "Id zdarzenia": "Event Id",
        "Identyfikator zadania": "",
        "Ilość wierszy": "Number of rows",
        "Ilość wyników do odrzucenia": "Number of results to be rejected",
        "Ilość wyników do pobrania": "Number of results to download",
        "Ilość znaków": "Number of characters",
        "Imię": "First name",
        "Imię i Nazwisko": "First name and last name",
        "Indywidualne tokeny": "",
        "Inspekcja": "Inspection",
        "Jedna kolumna": "One column",
        "Kalendarz - Kategorie zasobów": "Calendar - Resource Categories",
        "Kanał jest domyślnie wymagany. Nie da się wyłączyć wysyłania powiadomienia tym kanałem. Możliwa jest tylko zmiana tytułu i treści komunikatu.": "The channel is required by default. It is not possible to stop sending a notification through this channel. It is only possible to change the title and content of the message.",
        "kanał wymagany": "required channel",
        "Kanały komunikacji": "Communication channels",
        "Kanały obsługiwane przez wybrany typ powiadmienia": "Channels supported by the selected notification type",
        "Kanały włączone w ustawieniach aplikacji": "Channels enabled in app settings",
        "Katalog": "Directory",
        "Katalog został utworzony.": "The directory has been created.",
        "Kategoria": "Category",
        "Kategoria jest wymagana": "Category is required",
        "Kategoria została dodana.": "The category has been added.",
        "Kategoria została usunięta.": "The category has been removed.",
        "Kategoria została zaktualizowana.": "The category has been updated.",
        "Kategorie": "Categories",
        "Klienci": "Clients",
        "Klient aktywny": "Active client",
        "Klient został dodany.": "The client has been added.",
        "Klient został usunięty.": "The client has been deleted.",
        "Klient został zaktualizowany.": "The client has been updated.",
        "Klucze": "Keys",
        "Kod pocztowy": "Postal code",
        "Kolejka": "",
        "Komunikat": "Message",
        "Komunikat błędu": "Error message",
        "Komunikat walidacji": "Validation message",
        "Konfiguracja domyślna": "Default configuration",
        "Konfiguracja harmonogramu": "",
        "Kontakt": "",
        "Kontakty - Typ działania": "Contacts - Activity Type",
        "Kontener plików": "File container",
        "Konto pozostanie nieaktywne dopóki adres e-mail nie zostanie potwierdzony": "",
        "Kontrolka wyboru (pojedynczy wybór)": "Choice control (single choice)",
        "Kontrolka wyboru (wybór wielokrotny)": "Choice control (multiple choice)",
        "Kontrolki podstawowe": "Basic controls",
        "Kontrolki specjalizowane": "Specialized controls",
        "Kopiuj": "Copy",
        "Kopiuj filtr": "Copy filter",
        "Kosz": "Bin",
        "Kraj": "Country",
        "Kroki": "Steps",
        "Kwiecień": "April",
        "Lewy panel": "Left panel",
        "Liczba": "Number",
        "Liczba całkowita": "Integer",
        "Liczba dziesiętna": "Decimal",
        "Linia": "Line",
        "linie": "Lines",
        "Lipiec": "July",
        "Lista efektywnych uprawnień użytkownika wynikających z uprawnień indywidualnych oraz ról.": "List of effective user permissions resulting from individual permissions and roles.",
        "Lista jest pusta": "The list is empty",
        "Lista plików": "File list",
        "Lista powiadomień": "Notification list",
        "Lista rozwijana": "Drop-down list",
        "Lista urządzeń na których jesteś zalogowany": "List of devices on which you are logged in",
        "Listopad": "November",
        "Litery": "Letters",
        "Litery i cyfry": "Letters and numbers",
        "Login": "Login",
        "Logo": "Logo",
        "Logowanie": "Log in",
        "Logowanie zakończyło się niepowodzeniem.": "Login failed.",
        "Luty": "February",
        "Łączenie treści": "Combining content",
        "Maj": "May",
        "Maksimum": "Maximum",
        "Maksymalna ilość plików": "Maximum number of files",
        "Maksymalny rozmiar pliku w MB": "Maximum file size in MB",
        "Małe znaki": "Small characters",
        "Marzec": "March",
        "Miejsca po przecinku": "Decimal points",
        "Miejsce logowania": "Sign in place",
        "Miejscowość": "Town",
        "Miesiąc": "Month",
        "Miesiące": "",
        "Migracje": "Migrations",
        "Miniaturki": "Thumbnails",
        "Minimalna długość hasła": "Minimum password length",
        "Minimum": "Minimum",
        "Minuty": "",
        "Mnożenie": "Multiplication",
        "Modyfikacja": "Modification",
        "Moje filtry": "My filters",
        "Moje konto": "My account",
        "Monitor kolejek": "Queue monitor",
        "Monitoring": "Monitoring",
        "Możliwość modyfikacji tego działu jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "The possibility of modifying this section is limited. Inactive fields are synchronized with the external database %0.",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "The possibility to modify this account is limited. Inactive fields are synchronized with the external database %0.",
        "Mój tytuł": "",
        "Na pewno przenieść wybrane elementy do bieżącego katalogu?": "Are you sure you want to move the selected elements to the current directory?",
        "Na pewno usunąć wybrane elementy?": "Are you sure to delete the selected elements?",
        "Nadawca": "",
        "Narzędzia": "Tools",
        "Następna strona": "Next page",
        "Nazwa": "Name",
        "Nazwa bazy danych": "Database name",
        "Nazwa działu": "Department name",
        "Nazwa katalogu": "Directory name",
        "Nazwa katalogu lub pliku": "Directory or file name",
        "Nazwa katalogu została zmieniona.": "The directory has been renamed.",
        "Nazwa kategorii": "Category name",
        "Nazwa klienta": "Client name",
        "Nazwa kolumny z opisem": "Description column name",
        "Nazwa kolumny z wartością": "Column name with value",
        "Nazwa konta (Klucz dostępu)": "Account Name (Access Key)",
        "Nazwa nadawcy": "Sender name",
        "Nazwa obiektu": "Object name",
        "Nazwa pliku": "File name",
        "Nazwa pliku została zmieniona.": "The file has been renamed.",
        "Nazwa pola": "Field name",
        "Nazwa przycisku ``Dalej``": "Button name ''Next''",
        "Nazwa przycisku ``Wstecz``": "Button name ''Back''",
        "Nazwa przycisku ``Wyślij``": "Button name ''Send''",
        "Nazwa roli": "Role name",
        "Nazwa serwera": "Server name",
        "Nazwa szablonu": "Template name",
        "Nazwa użytkownika": "User name",
        "Nazwa użytkownika serwisowego": "Maintenance user name",
        "Ta strona jest chroniona przez reCAPTCHA i Google": "This site is protected by reCAPTCHA and the Google.",
        "Polityka prywatności": "Privacy Policy",
        "i": "and",
        "Warunki korzystania z usług Google": "Terms of Service",
        "Nazwa zadania": "Task name",
        "Nazwa zespołu": "Team name",
        "Nazwa źródła": "Source name",
        "Nazwa źródła danych": "Data source name",
        "Nazwisko": "Last name",
        "Neutralne": "Neutral",
        "Nie": "No",
        "Nie mam zdania": "I have no opinion",
        "Nie masz uprawnień do panelu administracyjnego.": "You do not have permission to access the administration panel.",
        "Nie masz wymaganych uprawnień do wykonania wybranej operacji.": "You do not have the required permissions to perform the selected operation.",
        "Nie możesz modyfikować uprawnień": "You cannot modify permissions",
        "Nie można dodać pliku(ów): \"%0\". Można dodawać tylko pliki typu: \"%1\".": "Unable to add file(s): \"%0\". You can only add files of type: \"%1\".",
        "Nie można usunąć swojego konta.": "You can't delete your account.",
        "Nie podano wymaganej ilości znaków: %0.": "The required number of characters was not given: %0.",
        "Nie udało się pobrać listy działów": "Failed to download the sections list",
        "Nie udało się pobrać listy filtrów": "Failed to download filter list",
        "Nie udało się pobrać listy kanałów": "Failed to download channel list",
        "Nie udało się pobrać listy kolejek": "",
        "Nie udało się pobrać listy powiadomień": "Failed to download notification list",
        "Nie udało się pobrać listy ról": "Failed to download role list",
        "Nie udało się pobrać listy serwerów baz danych": "Failed to retrieve the database server list",
        "Nie udało się pobrać listy serwerów plików": "Failed to download the file server list",
        "Nie udało się pobrać listy typów modeli": "Failed to download the list of model types",
        "Nie udało się pobrać listy wydziałów": "Failed to download the list of sections",
        "Nie udało się pobrać listy zastępstw": "Failed to download the replacement list",
        "Nie udało się pobrać powiadomień.": "Failed to download notifications.",
        "Nie udało się pobrać listy zespołów": "Failed to download team list",
        "Nie udało się usunąć szablonu": "Failed to delete template",
        "Nie udało się wysłać pliku": "Failed to send file",
        "Nie udało się zapisać szablonu": "Failed to save template",
        "Nie wiem": "I don't know",
        "Nie wszystkie wybrane elementy zostały przeniesione. Nie posiadasz wymaganych uprawnień lub, w przypadku przenoszenia katalogów, katalog docelowy jest podkatalogiem katalogu źródłowego. Katalogi i pliki których nie udało się przenieść pozostały nadal w zakładce Wybrane.": "Not all selected items have been transfered. You do not have the required permissions or, if you are moving directories, the destination directory is a subdirectory of the source directory. Directories and files that could not have been transfered remain in the Selected tab.",
        "nie wymuszaj zmiany hasła": "do not force password change",
        "Nie zdefiniowano żadnych ról.": "No roles are defined.",
        "Nie znaleziono żadnych plików": "No files found",
        "Nie znaleziono żadnych wyników.": "No results found.",
        "Niepotwierdzeni": "Unconfirmed",
        "Nieprawidłowy adres email": "Invalid email address",
        "Nieprawidłowy adres URL": "Invalid URL",
        "Niestandardowy błąd": "Non-standard error",
        "Nieudane": "Failed",
        "Nieudane próby logowania": "Failed login attempts",
        "Nieważne": "Not important",
        "Niewidoczne": "Not visible",
        "Niezadowolony": "Unsatisfied",
        "Nigdy": "Never",
        "Nikomu nie przydzielono uprawnień": "No one has been granted permissions",
        "Nowa data": "New date",
        "Nowe hasło": "New password",
        "Numer domu": "House no",
        "Numer mieszkania": "Flat no",
        "Numer sesji": "Session number",
        "Numer telefonu": "",
        "Obojętny": "Indifferent",
        "Obok siebie": "Next to each other",
        "Obraz został wycięty.": "The image has been cut out.",
        "Oczekuje na wysłanie": "To be shipped",
        "od": "from",
        "Odejmowanie": "Subtraction",
        "Odepnij menu": "",
        "Odpowiedzi": "Responses",
        "Odpowiedź 1": "Answer 1",
        "Odpowiedź 2": "Answer 2",
        "Odpowiedź 3": "Answer 3",
        "Odpowiedź 4": "Answer 4",
        "Odpowiedź 5": "Answer 5",
        "Odrzucone": "Rejected",
        "Odstęp": "Space",
        "Odśwież": "",
        "Odznacz wszystkie": "Unselect all",
        "Ok": "Ok",
        "Opcje": "Options",
        "Opis": "Description",
        "Opis zespołu": "Team description",
        "Opis źródła": "Source description",
        "Opisy": "Descriptions",
        "Oraz nowe hasło": "And a new password",
        "Organizacja": "Organization",
        "Osoba zastępowana": "Person being substituted",
        "Osoba zastępująca": "Substitute",
        "Ostatnia aktywność": "Recent activity",
        "Ostatnia strona": "Last page",
        "Parametry, wymagane do wykonania zapytania:": "Parameters required to execute the request:",
        "Pasek": "Bar",
        "Pasek postępu": "Progress bar",
        "Październik": "October",
        "Pierwsza strona": "First page",
        "Plik jest wysyłany": "The file is being sent",
        "Plik został wysłany": "The file has been sent",
        "Pliki do wysłania: %0": "Files to send: %0",
        "Pliki prywatne": "Private files",
        "Pliki publiczne": "Public files",
        "po %0 nieudanych próbach logowania": "after %0 unsuccessful login attempts",
        "Po zaznaczeniu tej opcji uprawnienia indywidualne i pochodzące z ról nie są brane pod uwagę.": "When you select this option, individual and role permissions will not be considered.",
        "Pobierz plik": "Download file",
        "Poczta": "Mail",
        "Podaj swój adres email": "Enter your email address",
        "Podana nazwa jest zajęta.": "The name you entered is already taken.",
        "Podana wartość jest nieprawidłowa.": "The provided value is not valid.",
        "Podanie nazwy jest wymagane.": "Name is required.",
        "Podano nieprawidłowe dane": "Invalid data provided.",
        "Podano nieprawidłowe dane.": "Invalid data provided.",
        "Podgląd": "Preview",
        "Podpis": "Signature",
        "Podstawowe operacje na datach": "Basic date operations",
        "Podstawowe operacje na liczbach": "Basic digits operations",
        "Podstawowe operacje tekstowe": "Basic text operations",
        "Podstawowe operacje warunkowe": "Basic conditional operations",
        "Podziedziałek - Piątek": "",
        "Pojedynczy wybór": "Single choice",
        "Pokaż etykietę": "Show tag",
        "Pokaż filtry": "Show filters",
        "Pokaż historię": "Show history",
        "Pokaż numery stron": "Show page numbers",
        "Pokaż przycisk ``Wyślij``": "Show ''Send'' button",
        "Pokaż szczegóły": "Show details",
        "Pokaż tylko moje dokumenty": "",
        "Pokaż tytuł formularza": "Show form title",
        "Pokaż tytuł sekcji": "Show section title",
        "Pokaż tytuł strony": "Show page title",
        "Pokaż tytuły stron": "Show page titles",
        "Pokaż więcej": "Show more",
        "pokaż więcej...": "show more...",
        "Pole \"%0\" jest wymagane.": "Field ''%0'' is required.",
        "Pole \"%0\" nie zawiera prawidłowego adresu email.": "Field ''%0'' does not contain a valid email address.",
        "Pole \"%0\" nie zawiera prawidłowego adresu URL.": "Field ''%0'' does not contain a valid URL address.",
        "Pole \"Drugie imię\" jest wymagane.": "''Middle name'' is required.",
        "Pole \"Imię\" jest wymagane.": "''Name'' is required.",
        "Pole \"Nazwisko\" jest wymagane.": "''Surname'' is required.",
        "Pole \"Tytuł\" jest wymagane.": "''Title'' is required.",
        "Pole może zawierać jedynie cyfry.": "Field can only contain numbers.",
        "Pole może zawierać jedynie litery i cyfry.": "This field can contain only letters and numbers.",
        "Pole może zawierać jedynie litery.": "This field can only contain letters.",
        "Pole wymagane": "Field required",
        "Pole zawiera": "Field contains",
        "Polski": "Polish",
        "Pomoc": "Help",
        "Poprawne": "Correct",
        "Poprzednia strona": "Previous page",
        "Porównaj %0": "Compare %0",
        "Porównaj z edytowaną wersją": "Compare with edited version",
        "Porównania": "Comparison",
        "Porównanie": "Comparison",
        "Porównywana wersja": "Compared version",
        "Port": "Port",
        "Posiada załączniki": "",
        "Potwierdzenie przywrócenia": "Confirmation of restoration",
        "Potwierdzenie usunięcia": "Deletion confirmation",
        "Potwierdzenie zmiany statusu": "Confirmation of status change",
        "Potwierdź akceptację": "Confirm acceptance",
        "Powiadomienia": "Notifications",
        "Powiadomienia zostały skonfigurowane.": "Notifications have been configured.",
        "Powiadomienie jest domyślnie wymagane. Nie da się wyłączyć wysyłania tego powiadomienia.": "Notification is required by default. You cannot stop this notification to appear.",
        "Powiadomienie testowe zostało wysłane.": "",
        "Powiększ": "Zoom in",
        "Powrót": "Back",
        "Powtórz hasło": "Repeat password",
        "Powtórzone": "Repeated",
        "Pozostało %0 znaków.": "There are %0 characters left.",
        "Pozycja": "Position",
        "Prawy panel": "Right panel",
        "Procent": "Percentage",
        "Profil użytkownika": "Profile",
        "Profil został zaktualizowany.": "The profile has been updated.",
        "Projekt": "Project",
        "Prywatne": "Private",
        "Przeciągnij i upuść aby posortować pliki": "Drag and drop to sort files",
        "Przedrostek": "Prefix",
        "Przeglądarka z której korzystasz nie wspiera powiadomień PUSH": "The browser you are using does not support PUSH notifications",
        "Przekroczono dozwoloną ilość znaków: %0.": "Allowed number of characters exceeded: %0.",
        "Przełącznik": "Switcher",
        "Przeniesienie wybranych elementów": "Transfer selected items",
        "Przenieś": "Move",
        "Przenieś wybrane": "Move selected",
        "Przesuń do góry": "Move up",
        "Przesuń na dół": "Move down",
        "Narzędzie integruje się z platformą SMSAPI.pl": "The tool integrates with the SMSAPI.pl platform",
        "Przetestuj ustawienia poczty": "Test your mail settings",
        "Przetestuj ustawienia Sms": "",
        "Przetwarzane": "Processing",
        "Przybornik": "Kit",
        "Przypnij menu": "",
        "Przyrostek": "Suffix",
        "Przywróć": "Reload",
        "Przyznaj dostęp": "Grant access",
        "Publiczne": "Public",
        "Pytania": "Questions",
        "Pytanie 1": "Question 1",
        "Pytanie 2": "Question 2",
        "Pytanie 3": "Question 3",
        "Raczej się nie zgadzam": "I rather disagree",
        "Raczej się zgadzam": "I rather agree",
        "Radio": "Radio",
        "Ranga": "Rank",
        "Region": "Region",
        "Rejestr zmian": "Changelog",
        "Repozytorium plików": "File repository",
        "Resetuj": "Reset",
        "Rodzaj odpowiedzi": "Type of response",
        "Rok": "Year",
        "Rola": "Role",
        "Rola aktywna": "Active role",
        "Rola została dodana.": "The role has been added.",
        "Rola została usunięta.": "The role has been removed.",
        "Rola została zaktualizowana.": "The role has been updated.",
        "Role i uprawnienia": "Roles and permissions",
        "Role użytkownika": "User roles",
        "Rozmiar": "Size",
        "Rozmiar zdjęcia został zmieniony.": "The image has been resized.",
        "Równość": "Equality",
        "Satysfakcja": "Satisfaction",
        "Sekcja": "Section",
        "Serwer bazy danych": "Database server",
        "Serwer dostępny": "Server available",
        "Serwer plików": "File server",
        "Serwer został dodany.": "Server has been added.",
        "Serwer został usunięty.": "Server has been removed.",
        "Serwer został zaktualizowany.": "Server has been updated.",
        "Serwery plików": "File servers",
        "Sesja": "Session",
        "Sesja nr %0": "Session No . %0",
        "Sesja użytkownika": "User's session has expired.",
        "Sierpień": "August",
        "Skala 1-5": "Scale 1-5",
        "słowa": "words",
        "Słownik": "Dictionary",
        "Słownik dowolny": "Any dictionary",
        "Słownik systemowy": "System dictionary",
        "Sms": "",
        "Sortowanie malejąco": "Sort descending ",
        "Sortowanie rosnąco": "Sort ascending",
        "Sprawdzenie czy pusty": "Check if empty",
        "Stała wartość": "Constant value",
        "Stan powiadomienia": "Notification status",
        "Status": "Status",
        "Status dokumentu został zmieniony.": "The status of the document has been changed.",
        "Status edytowanej wersji dokumentu": "Status of the edited version of the document",
        "Status formularza został zmieniony.": "The status of the form has been changed.",
        "Stopka": "",
        "Strona %0": "Page %0",
        "Strona główna": "Home",
        "Strona została usunięta.": "The page has been deleted.",
        "Stwierdzenie": "Statement",
        "Styczeń": "January",
        "Symbol klienta": "Customer symbol",
        "Szablon został dodany.": "The template has been added.",
        "Szablon został usunięty.": "The template has been removed.",
        "Szablon został zaktualizowany.": "The template has been updated.",
        "Szczegóły": "Details",
        "Szczegóły zadania": "Task details",
        "Szczegóły zdarzenia": "Event details",
        "Szczegóły zdarzenia nr %0": "Details of event number %0",
        "Szerokość": "Width",
        "Szkic": "Draft",
        "Szukaj": "Search",
        "Szukaj we wszystkich wersjach": "",
        "Szukana fraza": "Search term",
        "Średnio": "Moderately",
        "Tak": "Yes",
        "Tak/Nie": "Yes/No",
        "Tekst": "Text",
        "Tekst alternatywny": "Alt text",
        "Tekst zastępczy": "Alternative text",
        "Telefon": "Phone",
        "Ten element nie zawiera menu kontekstowego.": "This item does not contain a context menu.",
        "Ten kanał ma możliwość wysyłania tekstu sformatowanego.": "This channel provides a possibility to send formatted text.",
        "Ten kanał nie ma możliwości wysyłania tekstu sformatowanego.": "This channel does not provide a possibility to send formatted text.",
        "Ten zespół nie posiada jeszcze żadnych członków.": "This team does not have any members yet.",
        "Teraz": "Now",
        "Terminy do słownika będzie można dodawać dopiero po zapisaniu źródła danych.": "You won't be able to add terms to the dictionary until you save the data source.",
        "Testowe powiadomienie": "",
        "Testowy adres email": "Tested email address",
        "Testowy numer telefonu": "",
        "To pole jest wymagane": "The field is required.",
        "Token": "",
        "Token został usunięty.": "",
        "Tokeny": "",
        "Treści odpowiedzi są wymagane": "Answers are required",
        "Treści pytań są wymagane": "Questions are required",
        "Treść": "Content",
        "Tryb konserwacji systemu": "System maintenancemMode",
        "Trzy kolumny": "Three columns",
        "Twoje hasło wygasło. Zmień je na nowe.": "Your password has expired. Change it to new one.",
        "Twój token": "",
        "Tydzień": "",
        "Tylko do odczytu": "Read-only",
        "tylko dokumenty wersjonowane": "",
        "Tylko serwer deweloperski. Nie używać w trybie produkcyjnym. ": "Development server only. Do not use in production mode. ",
        "Typ": "Type",
        "Typ konta": "Account type",
        "Typ mime": "Mime type",
        "Typ obiektu": "Object type",
        "Typ pola": "Field type",
        "Typ powiadomienia": "Notification type",
        "Typ serwera": "Server type",
        "Typ słownika": "Dictionary type",
        "Typ szablonu": "Template type",
        "Typ źródła": "Source type",
        "Typ źródła danych": "Data source type",
        "Tytuł": "Title",
        "Tytuł formularza jest wymagany": "Form title is required",
        "Tytuł formularza nie może być dłuższy niż %0 znaków": "The title of the form cannot be longer than %0 znaków",
        "Tytuł sekcji": "Section title",
        "Układ": "Arrangement",
        "Ulica": "Street",
        "Upload": "Upload",
        "Upload plików": "Upload files",
        "Uprawnienia": "Permissions",
        "Uruchom zadanie": "Run the task",
        "Urządzenie zostało usunięte.": "The device has been removed.",
        "Usługi": "Services",
        "Ustaw szerokości proporcjonalnie": "Set widths proportionally",
        "Ustawienia": "Settings",
        "Ustawienia konta": "Account settings",
        "Ustawienia powiadomień zostały zaktualizowane.": "Your notification settings have been updated.",
        "Ustawienia zaawansowane": "Advanced settings",
        "Ustawienia zostały zapisane.": "Settings have been saved.",
        "Usunął": "Deleted",
        "Usunięcie": "Deletion",
        "Usunięcie wybranych elementów": "Delete selected items",
        "Usuń": "Delete",
        "Usuń datę": "Delete date",
        "Usuń filtr": "Remove filter",
        "Usuń plik": "Delete file",
        "Usuń plik z listy": "Remove the file from the list",
        "Usuń spacje": "Remove spaces",
        "Usuń wybrane": "Delete selected",
        "Usuń z zespołu": "Remove from team",
        "Utworzył": "Created ",
        "Utwórz katalog": "Create a directory",
        "Uwzględnij wydziały podrzędne": "Include sub-departments",
        "Użytkownicy": "Users",
        "Użytkownicy w tej roli": "Users in this role",
        "Użytkownik": "User",
        "Użytkownik jest liderem zespołu": "The user is a team leader",
        "Użytkownik posiada pełne uprawnienia": "The user has full permissions",
        "Użytkownik został dodany do zespołu.": "The user has been added to the team.",
        "Użytkownik został dodany.": "The user has been added.",
        "Użytkownik został usunięty.": "User has been deleted.",
        "Użytkownik został zaktualizowany.": "The user has been updated.",
        "W każdy dzień tygodnia": "",
        "W organizacji nie ma jeszcze żadnych działów.": "There are no sections in the organization yet.",
        "W przyszłości": "In the future",
        "W zespole od %0": "",
        "W zespole od %0 do %1": "",
        "Walidacja": "Validation",
        "Walidacja certyfikatu SSL": "SSL certificate validation",
        "Wartości": "Values",
        "Wartość": "Value",
        "Wartość domyślna": "Default value",
        "Wartość musi być liczbą całkowitą": "The value must be an integer",
        "Wartość musi być liczbą dodatnią": "The value must be a positive number",
        "Wartość musi być liczbą ujemną": "The value must be a negative number",
        "Warunki logiczne": "Logical conditions",
        "Warunkowo": "Conditionally",
        "Ważne": "Important",
        "Ważne informacje": "Important information",
        "Ważność": "Validity",
        "Wczytaj zapisany filtr": "Load saved filter",
        "Weekend": "",
        "Wersja utowrzona przez": "Version created by",
        "Wersje": "Versions",
        "Wersje dokumentu": "Document versions",
        "Wewnętrznie": "Internally",
        "Wiadomość testowa została wysłana.": "A test message has been sent.",
        "Widoczność": "Visibility",
        "Wielkość kroku": "Step size",
        "Wielokrotny wybór": "Multiple choice",
        "więcej...": "",
        "Więcej...": "More…",
        "Więcej": "More",
        "Wklej komponent": "Paste component",
        "Włącz system powiadomień": "Enable the notification system",
        "Włączone": "Enabled",
        "Województwo": "Province",
        "Wpis domyślny": "Default entry",
        "Wpis został dodany.": "The post has been added.",
        "Wpis został usunięty.": "The post has been deleted.",
        "Wpis został zaktualizowany.": "The post has been updated.",
        "Wprowadzona wartość jest nieprawidłowa": "The value you entered is invalid",
        "Wprowadzona wartość jest za długa": "The value you entered is too long",
        "Wprowadzona wartość jest za krótka": "The value you entered is too short",
        "Wprowadzona wartość jest za niska": "The value you entered is too low",
        "Wprowadzona wartość jest za wysoka": "The value you entered is too high",
        "Wprowadzona wartość nie jest poprawna": "The value you entered is not valid",
        "Wróć do logowania": "Back to login",
        "Wróć do strony głównej": "Back to Home Page",
        "Wrzesień": "September",
        "Wstaw cztery kolumny 1/4": "Insert four columns 1/4",
        "Wstaw dwie kolumny 1/3 oraz 2/3": "Insert two columns 1/3 and 2/3",
        "Wstaw dwie kolumny 2/3 oraz 1/3": "Insert two columns 2/3 and 1/3",
        "Wstaw dwie równe kolumny": "Insert two equal columns",
        "Wstaw kontrolkę": "Insert control",
        "Wstaw stronę": "Insert page",
        "Wstaw szablon informacyjny ze zdjęciem": "Insert information template with photo",
        "Wstaw trzy kolumny 1/3": "Insert three columns 1/3",
        "Wstaw z dołu": "Insert from below",
        "Wstaw z góry": "Insert from above",
        "Wstaw z lewej strony": "Insert left",
        "Wstaw z prawej strony": "Insert right",
        "Wstecz": "Back",
        "Wszyscy": "Everyone",
        "Wszystkie": "All",
        "Wszystkie niezapisane zmiany zostaną utracone.": "Any unsaved changes will be lost.",
        "Wszystkie próby": "All attempts",
        "wybierz": "select",
        "wybierz długość": "choose length",
        "Wybierz plik": "Select a file",
        "Wybierz plik lub katalog": "Select a file or directory",
        "Wybierz pliki": "Select files",
        "Wybierz pliki (do {0})": "Select files (to {0})",
        "Wybierz serwer": "Select server",
        "Wybierz shortcode": "",
        "Wybierz typ powiadomienia aby zobaczyć dostępne klucze.": "Select the notification type to see the available keys.",
        "Wybierz użytkownika": "",
        "Wybierz zaznaczone": "Choose selected",
        "wybierz...": "select...",
        "Wybór": "Choice",
        "Wybór języka": "Language",
        "Wybrana data jest mniejsza niż dozwolona %0.": "Selected date is before the permitted one %0.",
        "Wybrana data jest większa niż dozwolona %0.": "Selected date is above the permitted one %0.",
        "Wybrana liczba jest mniejsza niż dozwolona %0.": "Selected number is lesser than allowed %0.",
        "Wybrana liczba jest większa niż dozwolona %0.": "Selected number is greater then allowed %0.",
        "Wybrane": "Selected",
        "Wybrane elementy zostały przeniesione.": "The selected items have been moved.",
        "Wybrane elementy zostały usunięte.": "The selected items have been removed.",
        "Wybrane pliki": "Selected files",
        "Wybrane pliki: {0}": "Selected files: {0}",
        "Wybrany język": "Selected language",
        "Język": "Language",
        "Wyczyść sesję": "Clear session",
        "Wydział": "Department",
        "Wykonaj": "Do",
        "Wyloguj": "Logout",
        "Wyloguj się": "Log out",
        "Wyloguj zalogowanego obecnie użytkownika": "",
        "Wymagaj znaków specjalnych w hasłach": "Require special characters in passwords",
        "Wymagane": "Required",
        "Wymagane jest ustawienie minimalnej długości hasła": "Minimum password length is required",
        "Wymagane jest wybranie źródła danych": "Data source is required",
        "Wymiary": "Dimensions",
        "Wymuś zmianę hasła": "Force password change",
        "Wyrażenia warunkowe": "Conditional expressions",
        "Wyrażenie regularne": "Regular expression",
        "Wysłane": "Sent",
        "Wysokość": "Height",
        "Wystąpił błąd połączenia. Upewnij się że masz włączony internet i spróbuj ponownie.": "A connection error has occurred. Make sure you are online and try again.",
        "Wystąpił błąd serwera podczas obsługiwania wybranej operacji. Spróbuj ponownie.": "A server error occurred while handling the selected operation. Please try again.",
        "Wysyłaj tym kanałem": "Send through this channel",
        "Wyszukaj": "Search",
        "Wyszukaj (Ctrl+Q)": "",
        "Wyszukaj wszędzie": "Search everywhere",
        "Wyszukaj...": "Search...",
        "Wyszukiwany ciąg znaków": "Search set of characters",
        "Wyślij": "Send",
        "Wyślij link": "Send link",
        "Wyświetl również zarchiwizowane formularze": "Display also archived forms",
        "Wyświetlam": "Displaying",
        "Wytnij": "Cut",
        "Wyzeruj licznik": "Reset counter",
        "Wzorzec": "Pattern",
        "Zaakceptowany": "Accepted",
        "Zabierz dostęp": "Take away access",
        "Zablokowany": "Blocked",
        "zacznij pisać aby wyszukać użytkownika...": "start typing to search for a user...",
        "Zacznik pisać Form lub Entry aby uruchomić podpowiadanie.": "Start typing Form or Entry to show the hints.",
        "Zaczyna sie od": "It starts with",
        "Zadanie zostało uruchomione.": "The task has started.",
        "Zadanie zostało włączone": "",
        "Zadanie zostało wyłączone": "",
        "Zadowolony": "Satisfied",
        "Zakolejkowane": "Pending",
        "Zakończone": "Completed",
        "Zakres czasu": "Time range",
        "Zaloguj się": "Sign In",
        "Zaloguj się na swoje konto": "Log in to your account",
        "Załączniki": "Attachments",
        "Zamiany zostały zapisane.": "The changes have been saved.",
        "Zamknij": "Close",
        "Zaokrąglenie": "Rounding",
        "Zapamiętaj logowanie": "Remember login",
        "Zapisz": "Save",
        "Zapisz filtr": "Save filter",
        "Zapisz filtry": "Save filters",
        "Zapisz i wróć": "Save and return",
        "Zapisz jako": "Save As..",
        "Zapisz zmiany": "Save changes",
        "Zaplanowane": "Scheduled",
        "Zapomniałeś hasła?": "Forgot your password?",
        "Zapytanie SQL": "SQL Query",
        "Zarchiwizowany": "Archived",
        "Zasięg": "Range",
        "Zastępstwa": "Substitutions",
        "Zastępstwo": "Add a substitution",
        "Zastępstwo wygasło.": "The substitution has expired.",
        "Zastępstwo wygaśnie za 10 minut.": "The substitution will expire in 10 minutes.",
        "Zastępstwo wygaśnie za 5 minut.": "The substitution will expire in 5 minutes.",
        "Zastępstwo wygaśnie za minutę.": "The substitution will expire in a minute.",
        "Zastępstwo zostało dodane.": "The substitution has been added.",
        "Zastępstwo zostało usunięte.": "The substitution has been removed.",
        "Zastępstwo zostało zaktualizowane.": "The substitution has been updated.",
        "Zastępuje osobę": "Substituting",
        "Zawiera": "Contains",
        "Zawsze": "Always",
        "Zaznacz wszystkie": "Select all",
        "Zdarzenie": "Event",
        "Zdecydowanie nie": "Definitely no",
        "Zdecydowanie nieważne": "Definitely not important",
        "Zdecydowanie się nie zgadzam": "I strongly disagree",
        "Zdecydowanie się zgadzam": "I strongly agree",
        "Zdecydowanie tak": "Definitely yes",
        "Zdecydowanie ważne": "Definitely important",
        "Zdjęcie użytkownika": "User's photo",
        "Zespoły": "Teams",
        "Zespół": "Team",
        "Zespół aktywny": "Active team",
        "Zespół został dodany.": "The team has been added.",
        "Zespół został usunięty.": "The team has been removed.",
        "Zespół został zaktualizowany.": "The team has been updated.",
        "Zgoda": "Consent",
        "Zmiana hasła": "Password change",
        "Zmiana motywu": "Theme change",
        "Zmiana rozmiaru obrazu": "Resize an image",
        "Zmień hasło": "Change password",
        "Zmień nazwę": "Rename",
        "Zmień nazwę katalogu": "Rename a directory",
        "Zmień nazwę pliku": "Rename a file",
        "Zmień rozmiar": "Resize",
        "Zmień status edytowanej wersji": "Change the status of the edited version",
        "Zmień ustawienia": "Change settings",
        "Zmniejsz": "Reduce",
        "Zmodyfikował": "Modified by",
        "znaki": "characters",
        "Zobacz sesję użytkownika": "View user session",
        "Zobacz szczegóły sesji użytkownika": "View user session details",
        "Zobacz szczegóły zdarzenia": "View event details",
        "Zobacz wszystkie": "See all",
        "Został przywrócony oryginalny harmonogram": "",
        "Zresetuj hasło": "Reset password",
        "Zwrócony": "Returned",
        "Źle": "Bad",
        "Źródła danych": "Data Sources",
        "Źródło": "Source",
        "Źródło danych": "Data source",
        "źródło danych zostało dodane.": "the data source has been added.",
        "Źródło danych zostało zaktualizowane.": "The data source has been updated.",
        "źródło zostało usunięte.": "the source has been removed.",
        "Żaden kanał komunikacji nie został włączony w ustawieniach powiadomień.": "No communication channel has been enabled in the notification settings.",
        "Wyszukaj plik": "Search for file",
        "Pobierz": "Download",
        "Mały układ siatki": "Small grid layout",
        "Duży układ siatki": "Large grid layout",
        "Układ listy": "List layout",
        "Właściwości": "Properties",
        "Generuj opis": "Generate description",
        "Zaznacz": "Select",
        "Odznacz": "Deselect",
        "Dodaj pliki": "Add files",
        "Dodaj nowy folder": "Add new folder",
        "Generator obrazów": "Image generator",
        "Opis obrazu": "Image description",
        "Co chcesz wygenerować?": "What do you want to generate?",
        "Proporcje obrazu": "Image proportions",
        "Styl obrazu": "Image style",
        "Kolor przewodni obrazu": "Main image color",
        "Generuj obraz": "Generate image",
        "Wybierz": "Select",
        "Trwa generowanie obrazu": "Image is being generated",
        "Trwa generowanie opisu dla wybranego obrazu": "A description for the selected image is being generated",
        "Nie udało się załadować widgetu AI.": "The AI ​​widget failed to load.",
        "Dostępne": "Accessible",
        "Wykorzystane": "Used",
        "Dostępny limit": "Available limit",
        "Licencja": "Licence",
        "Tagi": "Tags",
        "Design Studio": "Design Studio",
        "Repozytorium": "Repository",
        "Studio": "Studio",
        "System": "System",
        "Pozostałe": "Other",
        "Dokumenty": "Documents",
        "Formularze / Kategorie": "Forms / Categories",
        "Moduły": "Modules",
        "RESTful API": "RESTful API",
        "Źródła danych / Wartości": "Data Sources / Values",
        "Katalogi": "Directories",
        "Pliki": "Files",
        "Webhooks": "Webhooks",
        "Administracja / Organizacja": "Administration / Organization",
        "Administracja / Role": "Administration / Roles",
        "Administracja / Użytkownicy": "Administration / Users",
        "Administracja / Zespoły": "Administration / Teams",
        "Administracja / Powiadomienia": "Administration / Notifications",
        "Wypróbuj możliwości AI": "Try AI capabilities",
        "Przejdź do konfiguracji, aby włączyć narzędzia Asystenta AI.": "Go to settings to enable AI Assistant tools.",
        "Konfiguracja asystenta AI": "AI Assistant settings"
    }
};
