import { $t } from '@/plugins/localization';
import { Option } from './Interfaces';

enum ProgramIntegrationType{
    Demo = 'Demo',
    Test = 'Test',
    Lotto = 'Lotto'
}

enum SupplierIntegrationType{
    NoIntegration = 'NoIntegration',
    Action = 'Action',
    Amilon = 'Amilon',
    AGD_Warszawa = 'AGD_Warszawa',
    Asport = 'Asport',
    Campingaz_Coleman = 'Campingaz_Coleman',
    ComtelGroup = 'ComtelGroup',
    CzasZabawy = 'CzasZabawy',
    Kconsult = 'Kconsult',
    LechPol = 'LechPol',
    Makita = 'Makita',
    Nexto = 'Nexto',
    Skipwish = 'Skipwish',
    Zgora = 'Zgora',
    Allegro = 'Allegro',
    SPS = 'SPS',
    Digison = 'Digison',
    EpayEuronet = 'EpayEuronet'
}

enum PublicationEnum {
    Draft = 'Draft',
    Finished = 'Finished',
    Rejected = 'Rejected',
    Accepted = 'Accepted'
}

enum PhoneConnectionType{
    Inbound = "Przychodzące",
    Outbound = "Wychodzące",
}

enum PhoneConnectionStatus{
    Received = "Odebrane",
    Unanswered = "Nieodebrane",
}

enum PhoneConnectionSubject{
    LoginProblem = "Problem z logowaniem",
    PointCalculation = "Naliczanie punktów",
    PrizeStatus = "Status zamówionych nagród",
    PrizeComplaint = "Reklamacja otrzymanej nagrody",
    Other = "Inny"
}

enum BusinessType {
    CivilPartnership = "CivilPartnership",
    GeneralPartnership = "GeneralPartnership",
    JointStockCompany = "JointStockCompany",
    LimitedPartnership = "LimitedPartnership",
    LimitedLiabilityCompany = "LimitedLiabilityCompany",
    PartnershipCompany = "PartnershipCompany",
    SoleProprietorship = "SoleProprietorship",
}

enum ProductType {
    New = "New",
    Bestseller = "Bestseller",
    Promotion = "Promotion",
    Recommended = "Recommended",
    Voucher = "Voucher",
}

enum ProductCategoryType{
    Standard = "Standard",
    Dedicated = "Dedicated"
}

enum BannerType{
    Bottom = "Bottom",
    Top = "Top"
}

enum BannerRedirection{
    Categories = "Categories",
    Products = "Products",
    News = "News",
    Promotions = "Promotions",
    Bestsellers = "Bestsellers",
}

enum ComplaintStatus{
    New = "New",
    Forwarded = "Forwarded",
    InRepair = "InRepair",
    Resolved = "Resolved",
    Canceled = "Canceled"
}
enum ComplaintSecondaryStatus{
    RepairedAndSent = "RepairedAndSent",
    NewSent = "NewSent",
    WorkingSent = "WorkingSent",
    Resignation = "Resignation",
    Rejected = "Rejected",
    UnsentPointCorrection = "UnsentPointCorrection",
    ReplacementSent = "ReplacementSent",
    MissingItemSent = "MissingItemSent",
    CustomerSentProductToService = "CustomerSentProductToService",
    Other = "Other"
}

enum PrepaidCardOrderStatus{
    New = 'New',
    Accepted = 'Accepted',
    Billing = 'Billing',
    ToAcceptCardNumber = 'ToAcceptCardNumber',
    ToBePosted = 'ToBePosted',
    Posted = 'Posted',
    Suspended = 'Suspended',
    Canceled = 'Canceled',
    CardExpired = 'CardExpired'
}

enum BlikOrderStatus{
    Temp = "Temp",
    New = "New",
    Accepted = "Accepted",
    Completed = "Completed",
    Canceled = "Canceled",
    Expired = "Expired",
    Locked = "Locked",
    RejectedBZWBK = "RejectedBZWBK",
    RejectedPSP = "RejectedPSP",
    InProgress = "InProgress",
    SentToRealisation = "SentToRealisation",
    SentToCanceled = "SentToCanceled",
}

const BlikOrderStatusOptions = (): Option[] => ([
    { value: (BlikOrderStatus.Temp as any), text: $t('[[[Tymczasowe]]]'), icon: '' },
    { value: (BlikOrderStatus.New as any), text: $t('[[[Nowy]]]'), icon: '' },
    { value: (BlikOrderStatus.Accepted as any), text: $t('[[[Zaakceptowany]]]'), icon: '' },
    { value: (BlikOrderStatus.Completed as any), text: $t('[[[Ukończony]]]'), icon: '' },
    { value: (BlikOrderStatus.Canceled as any), text: $t('[[[Anulowany]]]'), icon: '' },
    { value: (BlikOrderStatus.Expired as any), text: $t('[[[Przeterminowany]]]'), icon: '' },
    { value: (BlikOrderStatus.Locked as any), text: $t('[[[Zablokowany]]]'), icon: '' },
    { value: (BlikOrderStatus.RejectedBZWBK as any), text: $t('[[[Odrzucona przez system BZWBK]]]'), icon: '' },
    { value: (BlikOrderStatus.RejectedPSP as any), text: $t('[[[Odrzucona przez system PSP]]]'), icon: '' },
    { value: (BlikOrderStatus.InProgress as any), text: $t('[[[Transakcja w drodze pomiędzy BZWBK a PSP]]]'), icon: '' },
    { value: (BlikOrderStatus.SentToRealisation as any), text: $t('[[[Do realizacji(wysłany do banku)]]]'), icon: '' },
    { value: (BlikOrderStatus.SentToCanceled as any), text: $t('[[[Zlecono anulowanie]]]'), icon: '' },
]);

const PrepaidCardOrderStatusOptions = (): Option[] => ([
    { value: (PrepaidCardOrderStatus.New as any), text: $t('[[[Nowe zamówienie]]]'), icon: '' },
    { value: (PrepaidCardOrderStatus.ToAcceptCardNumber as any), text: $t('[[[Do potwierdzenia numer karty]]]'), icon: '' },
    { value: (PrepaidCardOrderStatus.ToBePosted as any), text: $t('[[[Do zaksięgowania]]]'), icon: '' },
    { value: (PrepaidCardOrderStatus.Posted as any), text: $t('[[[Zaksięgowane]]]'), icon: '' },
    { value: (PrepaidCardOrderStatus.Suspended as any), text: $t('[[[Wstrzymane zaksięgowania]]]'), icon: '' },
    { value: (PrepaidCardOrderStatus.Canceled as any), text: $t('[[[Anulowane]]]'), icon: '' },
    { value: (PrepaidCardOrderStatus.CardExpired as any), text: $t('[[[Karta Visa straciła ważność]]]'), icon: '' },
]);

const ComplaintSecondaryStatusOptions = (): Option[] => ([
    { value: (ComplaintSecondaryStatus.RepairedAndSent as any), text: $t('[[[Naprawione i wysłane]]]'), icon: '' },
    { value: (ComplaintSecondaryStatus.NewSent as any), text: $t('[[[Wysłane nowe]]]'), icon: '' },
    { value: (ComplaintSecondaryStatus.WorkingSent as any), text: $t('[[[Sprzęt sprawny odesłany]]]'), icon: '' },
    { value: (ComplaintSecondaryStatus.Resignation as any), text: $t('[[[Rezygnacja z reklamacji]]]'), icon: '' },
    { value: (ComplaintSecondaryStatus.Rejected as any), text: $t('[[[Reklamacja odrzucona]]]'), icon: '' },
    { value: (ComplaintSecondaryStatus.UnsentPointCorrection as any), text: $t('[[[Niewysłany korekta punktowa]]]'), icon: '' },
    { value: (ComplaintSecondaryStatus.ReplacementSent as any), text: $t('[[[Wysłany zamiennik]]]'), icon: '' },
    { value: (ComplaintSecondaryStatus.MissingItemSent as any), text: $t('[[[Dosłano brakujący element]]]'), icon: '' },
    { value: (ComplaintSecondaryStatus.CustomerSentProductToService as any), text: $t('[[[Klient sam wysłał produkt do serwisu]]]'), icon: '' },
    { value: (ComplaintSecondaryStatus.Other as any), text: $t('[[[Inny]]]'), icon: '' },
]);

const ComplaintStatusOptions = (): Option[] => ([
    { value: (ComplaintStatus.New as any), text: $t('[[[Nowy]]]'), icon: '' },
    { value: (ComplaintStatus.Forwarded as any), text: $t('[[[Przekazany]]]'), icon: '' },
    { value: (ComplaintStatus.InRepair as any), text: $t('[[[W naprawie]]]'), icon: '' },
    { value: (ComplaintStatus.Resolved as any), text: $t('[[[Rozwiązany]]]'), icon: '' },
    { value: (ComplaintStatus.Canceled as any), text: $t('[[[Anulowany]]]'), icon: '' },
]);

const BannerTypeOptions = (): Option[] => ([
    { value: (BannerType.Bottom as any), text: $t('[[[Baner dolny]]]'), icon: '' },
    { value: (BannerType.Top as any), text: $t('[[[Baner górny]]]'), icon: '' },
]);

const BannerRedirectionOptions = (): Option[] => ([
    { value: (BannerRedirection.Categories as any), text: $t('[[[Kategoria]]]'), icon: '' },
    { value: (BannerRedirection.Products as any), text: $t('[[[Produkt]]]'), icon: '' },
    { value: (BannerRedirection.News as any), text: $t('[[[Nowość]]]'), icon: '' },
    { value: (BannerRedirection.Promotions as any), text: $t('[[[Promocja]]]'), icon: '' },
    { value: (BannerRedirection.Bestsellers as any), text: $t('[[[Bestseller]]]'), icon: '' },
]);

enum OrderStatus{
    New = "New",
    Accepted = "Accepted",
    Finalizing = "Finalizing",
    Sent = "Sent",
    Cancelled = "Cancelled",
    Realized = "Realized",
    ReturnedPoints_Cancelled = "ReturnedPoints_Cancelled",
    ReturnedPoints_Sent = "ReturnedPoints_Sent",
    Finished = 'Finished'
}

enum ProductStockType{
    Product = "Product",
    Order = "Order",
    Import = "Import",
    Integration = "Integration"
}

const OrderStatusOptions = (): Option[] => ([
    { value: OrderStatus.New, text: $t('[[[Nowe]]]'), icon: '' },
    { value: OrderStatus.Accepted, text: $t('[[[Zamówienie zaakceptowane]]]'), icon: '' },
    { value: OrderStatus.Finalizing, text: $t('[[[Kompletowanie zamówienia]]]'), icon: '' },
    { value: OrderStatus.Sent, text: $t('[[[Wysłane]]]'), icon: '' },
    { value: OrderStatus.Cancelled, text: $t('[[[Anulowane]]]'), icon: '' },
    { value: OrderStatus.Realized, text: $t('[[[Realizowane]]]'), icon: '' },
    { value: OrderStatus.ReturnedPoints_Cancelled, text: $t('[[[Anulowane (zwrot punktów)]]]'), icon: '' },
    { value: OrderStatus.ReturnedPoints_Sent, text: $t('[[[Wysłane (zwrot punktów)]]]'), icon: '' },
    { value: OrderStatus.Finished, text: $t('[[[Zakończone]]]'), icon: '' },
]);


const VatRatesOptions = (): Option[] => ([
    { value: (0 as any), text: $t('[[[0 %]]]'), icon: '' },
    { value: (5 as any), text: $t('[[[5 %]]]'), icon: '' },
    { value: (8 as any), text: $t('[[[8 %]]]'), icon: '' },
    { value: (19 as any), text: $t('[[[19 %]]]'), icon: '' },
    { value: (20 as any), text: $t('[[[20 %]]]'), icon: '' },
    { value: (23 as any), text: $t('[[[23 %]]]'), icon: '' },
]);

const ProductTypeOptions = (): Option[] => ([
    { value: (ProductType.New as any), text: $t('[[[Nowy]]]'), icon: '' },
    { value: (ProductType.Bestseller as any), text: $t('[[[Bestseller]]]'), icon: '' },
    { value: (ProductType.Promotion as any), text: $t('[[[Promocja]]]'), icon: '' },
    { value: (ProductType.Recommended as any), text: $t('[[[Rekomendowany]]]'), icon: '' },
    { value: (ProductType.Voucher as any), text: $t('[[[Voucher]]]'), icon: '' },
]);

const SupplierIntegrationTypeOptions = (): Option[] => ([
    { value: (SupplierIntegrationType.NoIntegration as any), text: $t('[[[Brak Integracji]]]'), icon: '' },
    { value: (SupplierIntegrationType.CzasZabawy as any), text: $t('[[[Czas Zabawy]]]'), icon: '' },
    { value: (SupplierIntegrationType.ComtelGroup as any), text: $t('[[[ComtelGroup]]]'), icon: '' },
    { value: (SupplierIntegrationType.Asport as any), text: $t('[[[Asport]]]'), icon: '' },
    { value: (SupplierIntegrationType.Skipwish as any), text: $t('[[[Skipwish]]]'), icon: '' },
    { value: (SupplierIntegrationType.Zgora as any), text: $t('[[[Zgora]]]'), icon: '' },
    { value: (SupplierIntegrationType.Kconsult as any), text: $t('[[[Kconsult]]]'), icon: '' },
    { value: (SupplierIntegrationType.LechPol as any), text: $t('[[[LechPol]]]'), icon: '' },
    { value: (SupplierIntegrationType.AGD_Warszawa as any), text: $t('[[[AGD Warszawa]]]'), icon: '' },
    { value: (SupplierIntegrationType.Action as any), text: $t('[[[Action]]]'), icon: '' },
    { value: (SupplierIntegrationType.Amilon as any), text: $t('[[[Amilon]]]'), icon: '' },
    { value: (SupplierIntegrationType.Campingaz_Coleman as any), text: $t('[[[Campingaz Coleman]]]'), icon: '' },
    { value: (SupplierIntegrationType.Nexto as any), text: $t('[[[Nexto]]]'), icon: '' },
    { value: (SupplierIntegrationType.Makita as any), text: $t('[[[Makita]]]'), icon: '' },
    { value: (SupplierIntegrationType.Allegro as any), text: $t('[[[Allegro]]]'), icon: '' },
    { value: (SupplierIntegrationType.SPS as any), text: $t('[[[SPS (GTPoland)]]]'), icon: '' },
    { value: (SupplierIntegrationType.Digison as any), text: $t('[[[Digison]]]'), icon: '' },
    { value: (SupplierIntegrationType.EpayEuronet as any), text: $t('[[[Epay Euronet]]]'), icon: '' }
]);

const ProductCategoryOptions = (): Option[] => ([
    { value: (ProductCategoryType.Standard as any), text: $t('[[[Kategoria standardowa]]]'), icon: '' },
    { value: (ProductCategoryType.Dedicated as any), text: $t('[[[Kategoria dedykowana]]]'), icon: '' },
]);

const ProductCategoryName = (value: ProductCategoryType): string => ProductCategoryOptions().find(p => p.value === value)?.text || ProductCategoryType[value];

const BusinessTypeOptions = (): Option[] => ([
    { value: (BusinessType.CivilPartnership as any), text: $t('[[[Spółka cywilna]]]'), icon: '' },
    { value: (BusinessType.GeneralPartnership as any), text: $t('[[[Spółka jawna]]]'), icon: '' },
    { value: (BusinessType.JointStockCompany as any), text: $t('[[[Spółka akcyjna]]]'), icon: '' },
    { value: (BusinessType.LimitedPartnership as any), text: $t('[[[Spółka komandytowa]]]'), icon: '' },
    { value: (BusinessType.LimitedLiabilityCompany as any), text: $t('[[[Spółka z o.o.]]]'), icon: '' },
    { value: (BusinessType.PartnershipCompany as any), text: $t('[[[Firma partnerska]]]'), icon: '' },
    { value: (BusinessType.SoleProprietorship as any), text: $t('[[[Jednoosobowa działalność]]]'), icon: '' },
]);

const PublicationOptions = (): Option[] => ([
    { value: PublicationEnum.Draft, text: $t('[[[Szkic]]]'), icon: 'fas fa-pen-circle text-info' },
    { value: PublicationEnum.Finished, text: $t('[[[Do akceptacji]]]'), icon: 'fas fa-clock text-warning' },
    { value: PublicationEnum.Rejected, text: $t('[[[Zwrócony]]]'), icon: 'fas fa-times-circle text-danger' },
    { value: PublicationEnum.Accepted, text: $t('[[[Zaakceptowany]]]'), icon: 'fas fa-check-circle text-success' }
]);

const AllPublicationOptions = (): Option[] => (
    [ { value: null, text: $t('[[[Wszystkie]]]') } ].concat(PublicationOptions())
);

const PublicationName = (value: PublicationEnum): string => PublicationOptions().find(p => p.value === value)?.text || PublicationEnum[value];
const PublicationIcon = (value: PublicationEnum): string => PublicationOptions().find(p => p.value === value)?.icon || '';
const PublicationStyle = (classTemplate: string, status: PublicationEnum): Record<string, boolean> =>
{
    const classes: Record<string, boolean> = {};
    const re = new RegExp('\\{0\\}', 'g');

    classes[classTemplate.replace(re, 'info')] = status === PublicationEnum.Draft;
    classes[classTemplate.replace(re, 'warning')] = status === PublicationEnum.Finished;
    classes[classTemplate.replace(re, 'danger')] = status === PublicationEnum.Rejected;
    classes[classTemplate.replace(re, 'success')] = status === PublicationEnum.Accepted;

    return classes;
};

const ProductStockTypeOptions = (): Option[] => ([
    { value: ProductStockType.Product, text: $t('[[[Karta produktu]]]'), icon: '' },
    { value: ProductStockType.Order, text: $t('[[[Zamówienie]]]'), icon: '' },
    { value: ProductStockType.Import, text: $t('[[[Import]]]'), icon: '' },
    { value: ProductStockType.Integration, text: $t('[[[Integracja]]]'), icon: '' }
]);

export {
    ComplaintSecondaryStatusOptions,
    ComplaintStatusOptions,
    ComplaintStatus,
    OrderStatusOptions,
    SupplierIntegrationTypeOptions,
    SupplierIntegrationType,
    ProductCategoryOptions,
    PhoneConnectionStatus,
    PhoneConnectionSubject,
    PhoneConnectionType,
    BusinessType,
    BannerTypeOptions,
    BannerRedirection,
    BannerRedirectionOptions,
    BusinessTypeOptions,
    ProgramIntegrationType,
    PublicationEnum,
    ProductCategoryName,
    PublicationOptions,
    PublicationName,
    PublicationStyle,
    PublicationIcon,
    AllPublicationOptions,
    VatRatesOptions,
    ProductTypeOptions,
    OrderStatus,
    PrepaidCardOrderStatus,
    PrepaidCardOrderStatusOptions,
    BlikOrderStatusOptions,
    ProductStockTypeOptions
};

// ----------------------------------------------------------------------------------------------------

enum EntityState {
    Deleted = 'Deleted',
    Modified = 'Modified',
    Added = 'Added',
    Trashed = 'Trashed',
    Recovered = 'Recovered'
}

const EntityStateOptions = (): Option[] => ([
    { value: EntityState.Deleted, text: $t('[[[Usunięcie]]]') },
    { value: EntityState.Modified, text: $t('[[[Modyfikacja]]]') },
    { value: EntityState.Added, text: $t('[[[Dodanie]]]') },
    { value: EntityState.Trashed, text: $t('[[[Kosz]]]') },
    { value: EntityState.Recovered, text: $t('[[[Przywrócenie]]]') }
]);

const EntityStateName = (value: EntityState): string => EntityStateOptions().find(p => p.value === value)?.text || EntityState[value];

export {
    EntityState,
    EntityStateOptions,
    EntityStateName
};

// ----------------------------------------------------------------------------------------------------

enum AccountType {
    Local = 'Local',
    Ldap = 'Ldap'
}

export {
    AccountType
};

// ----------------------------------------------------------------------------------------------------

enum DictionaryType {
    CoreUsers = 'CoreUsers',
    CoreRoles = 'CoreRoles',
    CoreTeams = 'CoreTeams',

    // CoreJobPositions = 'CoreJobPositions',
    // CoreCountries = 'CoreCountries',
    // CoreCities = 'CoreCities',
    // CoreProvinces = 'CoreProvinces',
    // ContactsEventTypes = 'ContactsEventTypes',
    // CalendarResourceCategories = 'CalendarResourceCategories'
}

const DictionaryTypeOptions = (): Option[] => ([
    { value: DictionaryType.CoreUsers, text: $t('[[[Administracja - Użytkownicy]]]') },
    { value: DictionaryType.CoreRoles, text: $t('[[[Administracja - Role]]]') },
    { value: DictionaryType.CoreTeams, text: $t('[[[Administracja - Zespoły]]]') }

    // { value: DictionaryType.CoreJobPositions, text: $t('[[[Administracja - Stanowiska pracy]]]') },
    // { value: DictionaryType.CoreCountries, text: $t('[[[Administracja - Kraje]]]') },
    // { value: DictionaryType.CoreCities, text: $t('[[[Administracja - Miasta]]]') },
    // { value: DictionaryType.CoreProvinces, text: $t('[[[Administracja - Województwa]]]') },
    // { value: DictionaryType.ContactsEventTypes, text: $t('[[[Kontakty - Typ działania]]]') },
    // { value: DictionaryType.CalendarResourceCategories, text: $t('[[[Kalendarz - Kategorie zasobów]]]') }
]);

const DictionaryTypeName = (value: DictionaryType): string => DictionaryTypeOptions().find(p => p.value === value)?.text || DictionaryType[value];

export {
    DictionaryType,
    DictionaryTypeOptions,
    DictionaryTypeName
};

// ----------------------------------------------------------------------------------------------------

enum QueueStage {
    DISPATCH = 100,
    QUEUED = 200,
    DELAYED = 300,
    RETRY = 400,
    REJECTED = 500,
    PROCESSING = 600,
    SUCCEEDED = 700,
    FAILED = 800
}

const QueueStageOptions = (): Option<QueueStage>[] => ([
    { value: QueueStage.DISPATCH, text: $t('[[[Wysłane]]]') },
    { value: QueueStage.QUEUED, text: $t('[[[Zakolejkowane]]]'), icon: 'fad fa-inbox-in text-info' },
    { value: QueueStage.DELAYED, text: $t('[[[Zaplanowane]]]'), icon: 'fad fa-clock text-info' },
    { value: QueueStage.RETRY, text: $t('[[[Powtórzone]]]') },
    { value: QueueStage.REJECTED, text: $t('[[[Odrzucone]]]') },
    { value: QueueStage.PROCESSING, text: $t('[[[Przetwarzane]]]'), icon: 'fad fa-gears text-warning' },
    { value: QueueStage.SUCCEEDED, text: $t('[[[Zakończone]]]'), icon: 'fad fa-check text-success' },
    { value: QueueStage.FAILED, text: $t('[[[Błędne]]]'), icon: 'fad fa-triangle-exclamation text-danger' }
]);

const QueueStageName = (value: QueueStage): string => QueueStageOptions().find(p => p.value === value)?.text || QueueStage[value];

export {
    QueueStage,
    QueueStageOptions,
    QueueStageName
};

// ----------------------------------------------------------------------------------------------------

enum JobStage {
    QUEUED = 210,
    PROCESSING = 610,
    SUCCEEDED = 620,
    FAILED = 630
}

const JobStageOptions = (): Option<JobStage>[] => ([
    { value: JobStage.QUEUED, text: $t('[[[Zakolejkowane]]]'), icon: 'fad fa-inbox-in text-info' },
    { value: JobStage.PROCESSING, text: $t('[[[Przetwarzane]]]'), icon: 'fad fa-gears text-warning' },
    { value: JobStage.SUCCEEDED, text: $t('[[[Zakończone]]]'), icon: 'fad fa-check text-success' },
    { value: JobStage.FAILED, text: $t('[[[Błędne]]]'), icon: 'fad fa-triangle-exclamation text-danger' }
]);

const JobStageName = (value: JobStage): string => JobStageOptions().find(p => p.value === value)?.text || JobStage[value];

export {
    JobStage,
    JobStageOptions,
    JobStageName
};
